import { FC, useRef } from 'react';

import { ExpandableContainer } from 'components/layout/ExpandableContainer';
import { ScrollController } from '../../../../elements/ScrollController';
import { Subheading } from 'components/typography/Subheading';
import { TableSystemGrid } from '../TableSystemGrid';
import { TableProductGrid } from '../TableProductGrid';

import { ISystemTableData } from '../../types';

import './SystemComponentsAccordion.styles.scss';

type SystemComponentsTableAccordionProps = {
  system: ISystemTableData;
  isOpen: boolean;
  onToggle: () => void;
};

const SystemComponentsTableAccordion: FC<SystemComponentsTableAccordionProps> = ({ system, onToggle, isOpen }) => {
  const {
    components,
    height_flashing,
    height_net,
    system_power,
    title,
    units,
    width_flashing,
    width_net,
    rows,
    columns,
  } = system;
  const scrollAbleRef = useRef<HTMLDivElement>(null);
  return (
    <div data-testid="SystemComponentsTableAccordion" className="system-components-accordion ">
      <TableSystemGrid
        onToggle={onToggle}
        isOpen={isOpen}
        data={{ height_flashing, height_net, system_power, title, units, width_flashing, width_net, rows, columns }}
      />
      <ExpandableContainer
        ref={scrollAbleRef}
        className="overflow-x-auto no-scrollbar overflow-y-hidden"
        isOpen={isOpen}
      >
        {components && components.length ? (
          <TableProductGrid components={components} />
        ) : (
          <div className="flex justify-center">
            <Subheading text="No component data" />
          </div>
        )}
      </ExpandableContainer>
      {isOpen && (
        <div className="pb-10 pt-6">
          <ScrollController elRef={scrollAbleRef} />
        </div>
      )}
    </div>
  );
};

SystemComponentsTableAccordion.propTypes = {};

export default SystemComponentsTableAccordion;
