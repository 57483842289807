import { FC } from 'react';
import './FaqAccordion.styles.scss';
import { FaqAccordionItem, FAQItem } from './components/FaqAccordionItem';

type Props = {
  list: FAQItem[];
};

const FaqAccordion: FC<Props> = ({ list }) => {
  return (
    <div data-testid="FaqAccordion" className="FaqAccordion">
      <div className="my-4">
        <h1 className="mt-6 md:mt-0 mb-6  font-bold text-2xl">Frequently asked questions</h1>
      </div>
      {list.map(({ title, content }, index) => (
        <FaqAccordionItem title={title} content={content} key={`${title}_${index}`} />
      ))}
    </div>
  );
};

FaqAccordion.propTypes = {};

export default FaqAccordion;
