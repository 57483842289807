import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CustomerFormType, IComponentSpecOut } from 'types/api';

interface ResultsInitialState {
  results: IComponentSpecOut | null;
  customer_data: CustomerFormType | null;
  formSubmitted: boolean;
}

const initialState: ResultsInitialState = {
  results: null,
  customer_data: null,
  formSubmitted: false,
};

const resultsSlice = createSlice({
  name: 'resultsSlice',
  initialState,
  reducers: {
    setResults: (state, { payload }: PayloadAction<IComponentSpecOut>) => {
      state.results = payload;
    },
    setCustomerData: (state, { payload }: PayloadAction<CustomerFormType>) => {
      state.customer_data = payload;
    },
    setFormSubmitted: (state, { payload }: PayloadAction<boolean>) => {
      state.formSubmitted = payload;
    },
  },
});

// export for use around the app
export const { setResults, setCustomerData, setFormSubmitted } = resultsSlice.actions;

// export for store
const resultsReducer = resultsSlice.reducer;

export default resultsReducer;
