import { SolarResTwo } from '../../../assets/images';
import { SolarResThree } from '../../../assets/images';
import { SolarResFour } from '../../../assets/images';
import { SolarResFive } from '../../../assets/images';
import { SolarResOne } from '../../../assets/images';
import PV from 'assets/images/pv_knwl.jpg';
export const RESOURCE_ITEMS = [
  {
    title: 'Resource List',
    items: [
      {
        text: 'Download and access our brochure, installation guides and technical information',
        img_url: SolarResOne,
        resource_url: 'https://www.wienerberger.co.uk/products/roof/in-roof-solar.html',
        external_url: true,
        btnText: 'Download',
      },
      {
        text: 'Access our PV knowledge and training base',
        img_url: PV,

        resource_url: 'https://www.wienerberger.co.uk/products/roof/in-roof-solar/installation-how-to.html',
        external_url: true,
        btnText: 'More Information',
      },
      {
        text: 'View our range of Inverters',
        img_url: SolarResThree,

        resource_url:
          'https://www.wienerberger.co.uk/products/roof/in-roof-solar/solar-batteries-inverters.html#inverters',
        external_url: true,
        btnText: 'More information',
      },
      {
        text: 'View our range of Batteries',
        img_url: SolarResFour,

        resource_url:
          'https://www.wienerberger.co.uk/products/roof/in-roof-solar/solar-batteries-inverters.html#battery',
        external_url: true,
        btnText: 'More information',
      },
      {
        text: 'Find your nearest Stockist for our in-roof solar systems',
        img_url: SolarResFive,

        resource_url: 'https://www.wienerberger.co.uk/find-a-stockist.html?1175_f_typeOfProducts=Energy+Solutions',
        external_url: true,
        btnText: 'Find Stockist',
      },
      // {
      //   resource_url: `${window.location.origin}/pdf/UK_MKT_ROF_DOC_In-Roof_Solar_Brochure.pdf`,
      //   resource_name: `UK_MKT_ROF_DOC_In-Roof_Solar_Brochure.pdf`,
      //   local_url: true,
      //   text: 'In Roof Solar Brochure',
      //   img_url: SolarResSix,
      // },
      // {
      //   resource_url: `${window.location.origin}/pdf/UK_MKT_ROF_DOC_Solar_installation_manual.pdf`,
      //   resource_name: `UK_MKT_ROF_DOC_Solar_installation_manual.pdf`,
      //   local_url: true,
      //   text: 'Solar installation manual',
      //   img_url: SolarResFour,
      // },
      // {
      //   resource_url: `${window.location.origin}/pdf/UK_MKT_TEC_DOC_In_roof_solar_technical_details.pdf`,
      //   resource_name: `UK_MKT_TEC_DOC_In_roof_solar_technical_details.pdf`,
      //   local_url: true,
      //   text: 'In roof solar technical details',
      //   img_url: SolarResThree,
      // },
      // {
      //   resource_url: `${window.location.origin}/pdf/UK_TEC_DOC_ROF_in-roof_Solar_Ventilation_Guidance.pdf`,
      //   resource_name: `UK_TEC_DOC_ROF_in-roof_Solar_Ventilation_Guidance.pdf`,
      //   local_url: true,
      //   text: 'In roof solar ventilation guidance',
      //   img_url: SolarResTwo,
      // },
      // {
      //   resource_url: `https://www.wienerberger.co.uk/products/roof/in-roof-solar/installation-how-to.html`,
      //   external_url: true,
      //   text: 'PV Knowledge and Training',
      //   img_url: SolarResFive,
      // },
    ],
  },
];

export const PRODUCTS_LIST = [
  {
    title: 'Product List',
    items: [
      {
        resource_url: 'some_url',
        text: 'Installation guide',
        img_url: SolarResTwo,
      },
      {
        resource_url: '',
        text: 'Installation guide',
        img_url: SolarResTwo,
      },
      {
        resource_url: 'some_url',
        text: 'Installation guide',
        img_url: SolarResTwo,
      },
    ],
  },
];

export const FAQS = [
  {
    title: 'What are the benefits of having in-roof solar panels?',
    content:
      'Solar has the potential to reduce your carbon footprint by using renewable energy and save money on your utility bill.',
  },
  {
    title: 'How much money will in-roof solar panels save me?',
    content:
      'The amount of money you save is determined by how much energy you use, the number of panels you have installed, the orientation of your roof, and the amount of sunlight received throughout the day.',
  },
  {
    title: 'How many in-roof solar panels can I fit on my roof?',
    content:
      'The limit comes down to the available roof space and taking into consideration any immovable objects on the roof such as Velux windows, dormers and chimneys which can hinder the number of solar panels for the roof space. ',
  },
  {
    title: 'What is the best orientation for in-roof solar panels?',
    content: (
      <>
        In-roof solar panels on the north elevation of the roof are not advised and will significantly impact the kWh
        output. For detailed calculations please refer to the{' '}
        <a
          href="https://energysavingtrust.org.uk/tool/solar-energy-calculator/"
          target="_blank"
          rel="noreferrer"
          className="hover:underline text-blue-700"
        >
          solar energy calculator
        </a>{' '}
        through the Energy Saving Trust.
      </>
    ),
  },
  {
    title: 'How much will it cost?',
    content: (
      <>
        Detailed quotations and prices can be obtained from our Sandtoft stockists{' '}
        <a
          href="https://www.wienerberger.co.uk/find-a-stockist.html?1175_f_typeOfProducts=Roof"
          target="_blank"
          rel="noreferrer"
          className="hover:underline text-blue-700"
        >
          here.
        </a>
      </>
    ),
  },
];
