import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SectionContainer } from 'components/layout/SectionContainer';
import { MeasurementsForm } from 'components/features/MeasurementsForm';
import { Subheading } from 'components/typography/Subheading';
import { Heading } from 'components/typography/Heading';
import { TwoColumnContainer } from 'components/layout/TwoColumnContainer';

import { ElevationType } from 'types/general';

import House from 'assets/images/measurements-house.png';

import './Measurements.styles.scss';

type Props = {};

const Measurements: FC<Props> = (props) => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type') as ElevationType;

  return (
    <div data-testid="Measurements" className="Measurements overflow-x-hidden">
      <SectionContainer>
        <TwoColumnContainer
          my={'my-0'}
          leftComponent={
            <>
              <div className="flex flex-col gap-6 mb-2 text-center items-center md:items-start md:text-left">
                <Heading text="Your Roof Dimensions" />
                <div className="flex flex-col gap-[4px] max-w-[350px]">
                  <Subheading text="Enter your roof measurements." />

                  <Subheading
                    variant="sm"
                    text="Please be mindful of soil pipes, vents, Velux windows, and dormers, as this affects available roof
                  space."
                  />
                </div>
                {/* <div className=" pl-20 md:pl-0">
                  <AnimatedInfoIcon
                    text="Please note a system on the North elevation of the roof is not advised and will significantly impact the kWh output"
                    origin="left"
                  />
                  
                  
                </div> */}
                <div className="max-w-[400px]">
                  <Subheading
                    variant="sm"
                    text="Please note a system on the North elevation of the roof is not advised and will significantly impact the kWh output."
                  />
                </div>
              </div>
              <MeasurementsForm type={type} />
            </>
          }
          rightComponent={
            <div className="relative w-full md:left-[-10%] lg:left-[-20%] mt-10 md:mt-0 p-10 md:px-0">
              <p className="absolute  right-[12%] md:right-[10%] top-[20%] translate-y-[-50%] font-bold">H</p>
              <img src={House} alt="house" className=" z-[-1]" />
              <p className="absolute left-[50%] top-[2%] md:top-0 translate-x-[-50%] font-bold">W</p>
            </div>
          }
        />
      </SectionContainer>
    </div>
  );
};

Measurements.propTypes = {};

export default Measurements;
