import { CSSProperties, FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { ConfiguratorGridRow } from './components/ConfiguratorGridRow';
import { ScrollController } from '../../elements/ScrollController';

// redux
import {
  onDragEnd,
  populateGrid,
  setGrid,
  setWarningsAndValidSystems,
} from 'store/slices/configurator/configurator.slice';

// types
import { AppDispatch, RootState } from 'types/store';
import { CELL_HEIGHT, CELL_WIDTH } from './types';

// styles
import './Configurator.styles.scss';
import { useEventListener } from '../../../hooks/useEventListener';
import { getUnitsText } from 'utilities/helpers/getUnitsText';

type ConfiguratorProps = {
  max_height: number;
  max_length: number;
  elevationIndex: number;
};

const Configurator: FC<ConfiguratorProps> = ({ max_height, max_length, elevationIndex }) => {
  const dispatch: AppDispatch = useDispatch();

  const grid = useSelector((state: RootState) => state.configurator.grid);
  const measurements = useSelector((state: RootState) => state.measurements.measurements);
  const unitsText = getUnitsText(measurements?.units || 'metres');
  const elevationSelected = measurements?.elevations?.[elevationIndex];
  const gridSelected = measurements?.elevations?.[elevationIndex]?.grid;

  const scrollAbleRef = useRef<HTMLDivElement>(null);

  const length = max_length || 0;
  const height = max_height || 0;

  useEffect(() => {
    if (gridSelected) {
      dispatch(populateGrid(gridSelected));
      dispatch(setWarningsAndValidSystems());
    } else {
      dispatch(setGrid({ height, length }));
      dispatch(setWarningsAndValidSystems());
    }
  }, [dispatch, gridSelected, height, length]);

  const gridStyles: CSSProperties = useMemo(() => {
    return {
      gridTemplateColumns: `repeat(${length}, ${CELL_WIDTH})`,
      gridTemplateRows: `repeat(${height}, ${CELL_HEIGHT}) `,
    };
  }, [length, height]);

  const isDragging = useSelector((state: RootState) => state.configurator.isDragging);

  const onMouseLeave = useCallback(() => {
    if (isDragging) {
      dispatch(onDragEnd());
      dispatch(setWarningsAndValidSystems());
    }
  }, [dispatch, isDragging]);

  useEventListener('mouseup', onMouseLeave);

  if (!grid?.length) return null;

  const measurementsHeight = `${elevationSelected?.height}${unitsText}`;
  const measurementsWidth = `${elevationSelected?.width}${unitsText}`;

  return (
    <div className="w-full flex flex-col items-center">
      <div ref={scrollAbleRef} className="scroll-smooth max-w-[100%] no-scrollbar  flex gap-2 overflow-x-scroll mb-8">
        <div className="configurator-wrapper  relative p-6  mr-[3px] pb-0 px-[100px]">
          <div className="configurator-surface-wrapper p-4 rounded-sm min-w-[max-content] mt-6">
            <p className="absolute left-[50%] translate-x-[-50%] font-bold text-[12px] -top-[40px]">
              {measurementsWidth}
            </p>
            <div
              style={gridStyles}
              className="configurator-grid grid min-h-[max-content] border-[1.5px] border-solid border-gray-400"
            >
              {grid?.map((row, i) => (
                <ConfiguratorGridRow key={`row-${i}`} row={row} />
              ))}
            </div>
            <p className="absolute top-[50%] translate-y-[-50%] configration-right-point font-bold text-[12px]">
              {measurementsHeight}
            </p>
          </div>
        </div>
      </div>
      <div className="w-[90%] lg:w-[70%] mb-[20px]">
        <ScrollController elRef={scrollAbleRef} />
      </div>
    </div>
  );
};

Configurator.propTypes = {};

export default Configurator;
