import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox } from '../../Checkbox';

type RHFCheckboxProps = {
  name: string;
  disabled?: boolean;
  label?: string | React.ReactNode;
};

const RHFCheckbox: React.FC<RHFCheckboxProps> = ({ name, disabled, label }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Checkbox label={label} onChange={onChange} checked={value} disabled={disabled} error={error?.message} />
      )}
    />
  );
};

RHFCheckbox.propTypes = {};

export default RHFCheckbox;
