import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { SelectOption } from 'types/form';
import { Select } from '../../Select';

type RHFSelectProps = {
  name: string;
  disabled?: boolean;
  options: SelectOption[];
  label?: string;
};

const RHFSelect: React.FC<RHFSelectProps> = ({ name, disabled, options, label }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Select options={options} onChange={onChange} value={value} disabled={disabled} error={error?.message} label={label} />
      )}
    />
  );
};

export default RHFSelect;
