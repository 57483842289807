import { FC } from 'react';

type Props = {
  text: string;
};

const ResourceText: FC<Props> = ({ text }) => {
  return (
    <h3 data-testid="ResourceText" className="text-lg lg:text-21 ">
      {text}
    </h3>
  );
};

ResourceText.propTypes = {};

export default ResourceText;
