import { FC, ReactNode } from 'react';
import { cc } from 'utilities/styles/styles.util';

type TableProductCellProps = {
  children: ReactNode;
  variant: 'centered' | 'left';
  borderTop?: boolean;
};

const TableProductCell: FC<TableProductCellProps> = ({ children, variant, borderTop }) => {
  return (
    <div
      data-testid="TableProductCell"
      className={cc([
        'w-[160px] lg:w-full flex items-center min-w-[100px] py-4',
        variant === 'centered' ? 'justify-center' : '',
        borderTop ? 'border-t-2 border-t-gray-200 ' : '',
      ])}
    >
      {children}
    </div>
  );
};

TableProductCell.propTypes = {};

export default TableProductCell;
