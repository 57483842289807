import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { TextInput } from '../../TextInput';

type RHFTextInputProps = {
  name: string;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  variant?: 'outlined' | 'standard';
  fullWidth?: boolean;
  type?: string;
  colour?: 'white' | 'grey';
};

const RHFTextInput: React.FC<RHFTextInputProps> = ({
  name,
  disabled,
  label,
  placeholder,
  variant,
  fullWidth,
  type,
  colour = 'white',
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <TextInput
            onChange={(e) => {
              const value = e.target.value;
              if (type === 'number' && Number(value) <= 0) {
                return onChange('');
              }
              onChange(value);
            }}
            value={value}
            disabled={disabled}
            error={error?.message}
            label={label}
            placeholder={placeholder}
            variant={variant}
            fullWidth={fullWidth}
            type={type}
            colour={colour}
          />
        );
      }}
    />
  );
};

export default RHFTextInput;
