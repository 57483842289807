import { IUnits } from 'types/api';
import { GridItem } from 'types/grid';

type SystemWidthAndHeightInput = {
  height_flashing?: string;
  height_net?: string;
  width_flashing?: string;
  width_net?: string;
  units: IUnits;
};

type SystemWidthAndHeightText = {
  withoutFlashing: string;
  withFlashing: string;
};

export const generateSystemWidthAndHeightText = (
  widthAndHeight: SystemWidthAndHeightInput
): SystemWidthAndHeightText => {
  const { height_flashing, height_net, width_flashing, width_net, units } = widthAndHeight;

  const unit = units === 'mm' ? 'mm' : units === 'inches' ? 'in' : '';

  const withoutFlashing = `Height ${height_net || 'N/A'}${unit} X Width ${width_net || 'N/A'}${unit}`;
  const withFlashing = `Height ${height_flashing || 'N/A'}${unit} X Width ${width_flashing || 'N/A'}${unit}`;

  return { withFlashing, withoutFlashing };
};

export function hasPositionChanged(array: GridItem[][], referenceArray: GridItem[][]): boolean {
  if (!referenceArray.length) return false;
  for (let i = 0; i < array.length; i++) {
    for (let j = 0; j < array[i].length; j++) {
      const currentSelected: boolean = array[i][j].selected;
      const referenceSelected: boolean = referenceArray[i][j].selected;

      if (currentSelected !== referenceSelected) {
        return true;
      }
    }
  }
  return false;
}
