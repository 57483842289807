import { FC } from 'react';
import { motion } from 'framer-motion';

import { Close } from 'assets/images';

type Props = {
  title: string;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

const Modal: FC<Props> = ({ title, open, onClose, children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, pointerEvents: 'none' }}
      animate={{ opacity: open ? 1 : 0, pointerEvents: 'all' }}
      exit={{ opacity: 0, pointerEvents: 'all' }}
      className={`${
        open ? 'z-50' : 'z-100'
      } fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-20 backdrop-blur-sm`}
    >
      <div className="max-h-[85vh] md:max-h-full w-full max-w-[95vw] md:max-w-[900px] overflow-y-auto rounded-2xl bg-white p-4 md:p-8 lg:px-10 lg:py-8">
        <div className="w-full min-h-[100px]">
          <div className="flex items-start justify-between mb-4">
            <h2 className="text-xl lg:text-27 font-bold ">{title}</h2>
            <button onClick={onClose} className="transition-all active:scale-95">
              <img className="h-8 lg:h-10" src={Close} alt="close-btn" />
            </button>
          </div>
          {children}
        </div>
      </div>
    </motion.div>
  );
};

Modal.propTypes = {};

export default Modal;
