import { FC } from 'react';
import { LoadingSpinner } from 'components/elements/LoadingSpinner';

const DownloadSVG: FC = () => {
  return (
    <svg
      id="Group_1326"
      data-name="Group 1326"
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
    >
      <circle id="Ellipse_25" data-name="Ellipse 25" cx="10.5" cy="10.5" r="10.5" fill="#fff" />
      <g id="Icon_feather-download" data-name="Icon feather-download" transform="translate(0.873 0.666)">
        <path
          id="Path_58"
          data-name="Path 58"
          d="M14.377,22.5v2.195a1.1,1.1,0,0,1-1.1,1.1H5.6a1.1,1.1,0,0,1-1.1-1.1V22.5"
          transform="translate(0 -11.415)"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
        />
        <path
          id="Path_59"
          data-name="Path 59"
          d="M10.5,15l2.744,2.744L15.987,15"
          transform="translate(-3.805 -6.659)"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
        />
        <path
          id="Path_60"
          data-name="Path 60"
          d="M18,11.085V4.5"
          transform="translate(-8.561)"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

type Props = {
  onClick: () => void;
  isLoading?: boolean;
  text?: string;
  resource_url?: string;
  resource_name?: string;
  local_url?: boolean;
  external_url?: boolean;
};

const DownloadBtn: FC<Props> = ({
  onClick,
  isLoading,
  text = 'Download',
  resource_url,
  resource_name,
  external_url,
  local_url,
}) => {
  let registerDownload = (url:any) => {
    if (window.adobeDataLayer) {
      console.log("Downloading: ", url);
      window.adobeDataLayer.push({
          "event": "webDownloads",
          "downloads": [url]
      });
    }
  }
  const btnClassNames =
    'group transition-all cursor-pointer hover:bg-wb-red-dark active:scale-90 bg-wb-red text-white flex gap-2 items-center px-4 py-2 rounded-md max-w-max';
  return local_url || external_url ? (
    <a
      href={resource_url}
      download={local_url && resource_name}
      target={external_url ? '_blank' : ''}
      rel={external_url ? 'noreferrer' : ''}
      className={btnClassNames}
      onClick={() => { registerDownload(resource_url) }}
    >
      {text}
    </a>
  ) : (
    <button onClick={onClick} className={btnClassNames}>
      <span>{text}</span>
      {!isLoading ? <></> : <LoadingSpinner color="white" />}
    </button>
  );
};

DownloadBtn.propTypes = {};

export default DownloadBtn;
