import React from 'react';
import { PowerIcon } from 'components/elements/PowerIcon';
import { KWp } from 'components/typography/KWp';
import { wpToKwpConverter } from 'utilities/helpers/wpToKwpConverter';

type Props = {
  variant?: 'compact' | 'full-width';
  current: number;
  max: number;
  onMaxPossibleClick?: () => void;
};

const ElevationPower: React.FC<Props> = ({ variant = 'compact', current, max, onMaxPossibleClick }) => {
  if (variant === 'compact')
    return (
      <div data-testid="ElevationPower" className="rounded-20 bg-light-grey p-5 w-full">
        <div>
          <h4 className="text-21 font-bold mb-4 ">Total power</h4>
        </div>
        <div className="flex flex-col items-center lg:flex-row">
          <div className="flex items-center gap-2">
            <PowerIcon className="scale-125 mr-2" />
            <div>
              <p className="font-thin text-[15px] mb-[-6px] text-left">Current</p>
              <p className="font-bold text-27">
                {wpToKwpConverter(current)}
                <KWp className="ml-1 font-thin" />
              </p>
            </div>
          </div>
          <div className=" h-[2px] w-full md:w-[2px] md:h-[40px] md:my-auto bg-gray-200 my-4 lg:my-0 md:mx-8" />
          <div className="flex items-center gap-2">
            <PowerIcon className="scale-125 mr-2" />
            <div>
              <p className="font-thin text-[15px] mb-[-6px] text-left">Max possible</p>
              <p className="font-bold text-27">
                {wpToKwpConverter(max)}
                <KWp className="ml-1 font-thin" />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  else
    return (
      <div
        data-testid="ElevationPower"
        className="rounded-20 flex flex-col lg:flex-row lg:items-center  bg-light-grey px-5 py-3"
      >
        <div className="flex items-center mb-4 lg:mb-0">
          <PowerIcon className=" mr-3 lg:inline hidden " />
          <h4 className="text-21 lg:text-lg font-bold inline">System power</h4>
        </div>
        <div className=" h-[2px] w-full hidden lg:block  lg:w-[2px] lg:h-[25px] lg:my-auto bg-gray-200 my-3 lg:my-0 lg:mx-6" />

        <div className="flex">
          <PowerIcon className=" mr-4 inline lg:hidden scale-125 " />
          <div className="flex flex-col lg:flex-row lg:items-baseline lg:gap-2">
            <p className="font-thin text-[15px] ">Current</p>
            <p className="font-semibold flex items-baseline leading-tight lg:leading-[0px] text-27">
              {wpToKwpConverter(current)}
              <KWp className="ml-1 font-normal" />
            </p>
          </div>
        </div>

        <div className="h-[2px] w-full lg:w-[2px] lg:h-[25px] lg:my-auto bg-gray-200 my-3 lg:mx-6" />

        <div className="flex">
          <PowerIcon className=" mr-4 inline lg:hidden scale-125 " />
          <div className="flex flex-row items-center gap-[10px] lg:items-baseline lg:gap-2">
            <button
              className={`font-thin text-[15px] active:text-gray-400 text-left bg-black text-white px-4 py-2 rounded-md`}
              onClick={onMaxPossibleClick}
            >
              Max possible
            </button>
            <p className="font-semibold flex items-baseline leading-tight lg:leading-[0px] text-27">
              {wpToKwpConverter(max)}
              <KWp className="ml-1 font-normal" />
            </p>
          </div>
        </div>
      </div>
    );
};

ElevationPower.propTypes = {};

export default ElevationPower;
