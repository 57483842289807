import { FC } from 'react';

import './Heading.styles.scss';

import { cc } from 'utilities/styles/styles.util';

import { ElementClassName } from 'types/general';

type Props = { variant?: 'md' | 'lg'; text: string; color?: 'white' | void } & ElementClassName;

const Heading: FC<Props> = ({ variant = 'lg', text, color, className }) => {
  return (
    <h2
      data-testid="Heading"
      className={cc([
        'font-bold',
        variant === 'lg' ? 'text-5xl lg:text-67' : 'text-4xl lg:text-59',
        color === 'white' ? 'text-white' : '',
        className,
      ])}
    >
      {text}
    </h2>
  );
};

Heading.propTypes = {};

export default Heading;
