import { FC, useCallback, useState, useEffect } from 'react';
import { object } from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Modal } from 'components/elements/Modal';
import { RHFTextInput } from 'components/form/RHF/RHFTextInput';
import { ActionBtn } from 'components/buttons/ActionBtn';
import { RHFCheckbox } from 'components/form/RHF/RHFCheckbox';
import { RHFSelect } from 'components/form/RHF/RHFSelect';

import { Recaptcha } from 'assets/images';

import { useYupValidationResolver } from 'hooks/useYupResolver';
import { validation } from 'validation/validation';

import './DownloadResourcesForm.styles.scss';

import { CustomerFormType } from 'types/api';
import { RootState } from 'types/store';

import axiosInstance from 'services/axios';

type Props = {
  open: boolean;
  onClose(): void;
  onSubmitCallback(data: CustomerFormType): void;
};

const validationSchema = object({
  ...validation.download_form,
});

const DownloadResourcesForm: FC<Props> = ({ open, onClose, onSubmitCallback }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const resolver = useYupValidationResolver(validationSchema);
  const [businessTypes, setBusinessTypes] = useState<any>([{ label: 'Business Type*', value: '' }]);

  const customer_data = useSelector((state: RootState) => state.results.customer_data);

  const methods = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      mobile: '',
      company_name: '',
      business_type: '',
      post_code: '',
      robot: false,
      privacy_policy: false,
      subscribed: false,
    },
    resolver,
  });

  const handleSubmit = useCallback(
    async (data: CustomerFormType) => {
      try {
        setIsSubmitting(true);
        await axiosInstance.post('/create-order', data);

        onSubmitCallback(data);
      } catch (err) {
        console.log('🚀 err:', err);
      } finally {
        setIsSubmitting(false);
      }
    },
    [onSubmitCallback]
  );
  useEffect(() => {
    axiosInstance.get('/config/form-data').then((data: any) => {
      setBusinessTypes([
        { label: 'Business Type*', value: '' },
        ...data?.business_types.map((element: any) => {
          return { label: element?.business_type, value: element?.business_type };
        }),
      ]);
    });
  }, []);

  useEffect(() => {
    if (customer_data) {
      methods.reset({
        ...customer_data,
      });
    }
  }, [customer_data]);

  return (
    <Modal open={open} title="Download your product list and resources" onClose={onClose}>
      <p className="text-medium-grey text-sm mb-4">* Fields are mandatory</p>
      <div data-testid="DownloadResourcesForm" className="DownloadResourcesForm">
        <FormProvider {...methods}>
          <form>
            <div className="DownloadResourcesFormFields flex flex-col md:flex-row gap-8">
              <div className="YourDetails flex flex-col gap-4 flex-1">
                {/* <h2 className="font-bold text-xlg">Your Details</h2> */}
                <RHFTextInput name="first_name" variant="outlined" placeholder="First Name*" fullWidth />
                <RHFTextInput name="last_name" variant="outlined" placeholder="Last Name*" fullWidth />
                <RHFTextInput name="email" variant="outlined" placeholder="Email*" fullWidth />
                <RHFTextInput name="mobile" variant="outlined" placeholder="Mobile" fullWidth />
              </div>
              <div className="CompanyDetails flex flex-col gap-4 flex-1">
                {/* <h2 className="font-bold text-xlg">Company Details</h2> */}
                <RHFTextInput name="company_name" variant="outlined" placeholder="Company Name" fullWidth />
                <RHFSelect name="business_type" options={businessTypes} />
                <RHFTextInput name="post_code" variant="outlined" placeholder="Postcode*" fullWidth />
                <div className="flex justify-between items-center">
                  <RHFCheckbox name="robot" label="I am not a robot" />
                  <img src={Recaptcha} alt="recaptcha" width="55px" height="55px" />
                </div>
              </div>
            </div>
            <div className="TermsCheckboxes flex flex-col gap-4 my-8 mt-4">
              <RHFCheckbox
                name="privacy_policy"
                label={
                  <>
                    I agree to the
                    <Link
                      to="https://www.wienerberger.co.uk/privacy-policy.html"
                      className="underline hover:text-red"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      privacy policy
                    </Link>
                  </>
                }
              />
              <RHFCheckbox
                name="subscribed"
                label="I agree to be contacted about offers, products/services and the latest news from Wienerberger via email."
              />
            </div>
            <div className="DownloadResourcesButtons">
              <ActionBtn
                onClick={methods.handleSubmit(handleSubmit)}
                variant="submit"
                disabled={isSubmitting}
                isLoading={isSubmitting}
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};

DownloadResourcesForm.propTypes = {};

export default DownloadResourcesForm;
