import { FC } from 'react';

type Props = {
  src: string;
  alt: string;
};

const ResourceImage: FC<Props> = ({ src, alt }) => {
  return <img className=" object-cover md:h-[180px] lg:h-[200px]" src={src} alt={alt} />;
};

ResourceImage.propTypes = {};

export default ResourceImage;
