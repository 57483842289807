import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IMeasurementsOut } from 'types/api';
import { GridItem, IGrid } from 'types/grid';

interface MeasurementsInitialState {
  measurements: IMeasurementsOut | null;
}

const initialState: MeasurementsInitialState = {
  measurements: null,
};

const measurementsSlice = createSlice({
  name: 'measurementsSlice',
  initialState,
  reducers: {
    setMeasurements: (state, { payload }: PayloadAction<IMeasurementsOut>) => {
      state.measurements = payload;
    },
    resetMeasurements: (state) => {
      state.measurements = null;
    },
    setSystems: (state, { payload }: PayloadAction<{ index: number; grid: IGrid; validSystems: GridItem[][][] }>) => {
      const { index, grid, validSystems } = payload;

      if (state.measurements?.elevations[index]) {
        state.measurements.elevations[index].grid = grid;
        state.measurements.elevations[index].validSystem = validSystems;
        state.measurements.elevations[index].systems = validSystems.map((s) => {
          const rows = s.length;
          const columns = s[0].length;
          return { rows, columns };
        });
      }
    },
  },
});

// export for use around the app
export const { setMeasurements, resetMeasurements, setSystems } = measurementsSlice.actions;

// export for store
const measurementsReducer = measurementsSlice.reducer;

export default measurementsReducer;
