import { FC } from 'react';

import { GetInTouchCircle } from 'assets/images';

type Props = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const DownloadReourcesHere: FC<Props> = ({ onClick }) => {
  return (
    <button
      data-testid="DownloadReourcesHere"
      className="rounded-20 px-5 py-3 hover:opacity-95 active:scale-95 transition-all bg-wb-red text-white flex items-center gap-4"
      onClick={onClick}
    >
      <img src={GetInTouchCircle} alt="download-img" />
      <span className="max-w-[280px] text-xl font-semibold text-left">
        Download your product list and resources here
      </span>
    </button>
  );
};

export default DownloadReourcesHere;
