import { FC, useState } from 'react';
import './FaqAccordionItem.styles.scss';
import { ExpandableContainer } from 'components/layout/ExpandableContainer';
import { ChevronBtn } from 'components/buttons/ChevronBtn';

export type FAQItem = {
  title: string;
  content: string | JSX.Element;
};

const FaqAccordionItem: FC<FAQItem> = ({ title, content }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <div className="w-full light-border-bottom cursor-pointer">
      <div className="flex justify-between w-full h-20 items-center" onClick={() => setExpanded(!expanded)}>
        <h2 className="text-base md:text-2xl text-left md:text-center">{title}</h2>
        <div>
          <ChevronBtn open={expanded} colour="black" width={26} />
        </div>
      </div>
      <ExpandableContainer isOpen={expanded}>
        <p data-testid="FaqAccordionItem" className="FaqAccordionItem pb-4 ">
          {content}
        </p>
      </ExpandableContainer>
    </div>
  );
};

FaqAccordionItem.propTypes = {};

export default FaqAccordionItem;
