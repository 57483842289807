import { FC, ChangeEvent } from 'react';
import { RHFErrorMessage } from 'types/form';
import { FormError } from '../FormError';

interface InputProps {
  value?: string | number;
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: RHFErrorMessage;
  label?: string;
  placeholder?: string;
  variant?: 'outlined' | 'standard';
  fullWidth?: boolean;
  type?: string;
  colour?: 'white' | 'grey';
}

const TextInput: FC<InputProps> = ({
  value,
  onChange,
  disabled,
  error,
  label,
  placeholder,
  variant,
  fullWidth,
  type = 'text',
  colour = 'white',
}) => {
  return (
    <div className={`w-full`}>
      <div className="flex items-center justify-between">
        {label ? <label className="input-label flex-1 w-[30%]">{label}</label> : null}
        <input
          type={type}
          className={`outline-none py-1 px-2 rounded-lg w-[65%] box-border ${fullWidth ? 'w-full' : ''} ${
            variant === 'outlined' ? 'border-[1px] h-[40px] border-[#D0D0D0] rounded-[4px] pr-0' : ''
          } ${colour === 'grey' ? 'bg-light-grey' : 'bg-white'}`}
          value={value}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          min={1}
        />
      </div>
      {error && <FormError message={error} />}
    </div>
  );
};

TextInput.propTypes = {};

export default TextInput;
