import { FC, useState } from 'react';

import { RHFTextInput } from 'components/form/RHF/RHFTextInput';
import { RHFSelect } from 'components/form/RHF/RHFSelect';
import { ChevronBtn } from 'components/buttons/ChevronBtn';
import { ExpandableContainer } from 'components/layout/ExpandableContainer';
import Close from 'assets/images/close.svg';

import './ElevationSubForm.styles.scss';
import { Controller } from 'react-hook-form';
import { FormError } from 'components/form/FormError';

type Props = {
  heightName: string;
  widthName: string;
  unitsName: string;
  elevationFormName: string;
  elevation_name?: string;
  onRemove?: () => void;
  expand?: boolean;
  type: 'single' | 'multi';
};

const OPTIONS = [
  { label: 'inches', value: 'inches' },
  { label: 'mm', value: 'mm' },
];

const ElevationSubForm: FC<Props> = ({
  heightName,
  widthName,
  unitsName,
  elevation_name = '',
  onRemove,
  elevationFormName,
  expand = true,
  type,
}) => {
  const [expanded, setExpanded] = useState<boolean>(expand);
  return (
    <div
      data-testid="ElevationSubForm"
      className={`ElevationSubForm bg-light-grey p-4 py-6 md:p-6 rounded-[20px] md:max-w-[310px] flex flex-col relative`}
    >
      {onRemove && (
        <button onClick={onRemove} className="absolute left-[0px] top-[-11px] border-2 border-white rounded-full">
          <img src={Close} alt="close" width="24px" />
        </button>
      )}

      <div className={`elevation-header text-left flex items-center justify-between`}>
        <RHFTextInput name={elevation_name} label="" type="text" />
        {type !== 'single' && (
          <ChevronBtn
            open={expanded}
            width={24}
            onClick={type === 'multi' ? () => setExpanded(!expanded) : undefined}
          />
        )}
      </div>

      <ExpandableContainer isOpen={expanded}>
        <div className={`flex flex-col gap-4 ${elevation_name ? 'mt-4' : ''}`}>
          <RHFTextInput name={widthName} label="Width (W)" type="number" />
          <RHFTextInput name={heightName} label="Height (H)" type="number" />
          <RHFSelect name={unitsName} options={OPTIONS} label="Units:" />
          <Controller
            name={elevationFormName}
            render={({ fieldState: { error } }) => {
              return <>{error && <FormError message={error?.message || ''} />}</>;
            }}
          />
        </div>
      </ExpandableContainer>
    </div>
  );
};

ElevationSubForm.propTypes = {};

export default ElevationSubForm;
