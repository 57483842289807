import { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  customClasses?: string;
};

const ResourceContainer: FC<Props> = ({ children, customClasses }) => {
  return (
    <div
      data-testid="ResourceContainer"
      className={`ResourceContainer light-border-bottom resource-item-padding-bottom pt-4 ${customClasses}`}
    >
      {children}
    </div>
  );
};

ResourceContainer.propTypes = {};

export default ResourceContainer;
