import { FC, useCallback, useEffect } from 'react';
import './Home.styles.scss';
import { SectionContainer } from 'components/layout/SectionContainer';
import { Heading } from 'components/typography/Heading';
import { HouseTypeButton } from 'components/buttons/HouseTypeButton';
import { ElevationType } from 'types/general';
import { useNavigate } from 'react-router';
import { Subheading } from 'components/typography/Subheading';
import { PATH_ELEV_MEASUREMENTS } from 'constants/paths';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../types/store';
import { resetMeasurements } from '../../../store/slices/measurements/measurements.slice';
import { clearGrid } from '../../../store/slices/configurator/configurator.slice';

type Props = {};

const ELEVATIONS: ElevationType[] = ['single', 'multi'];

const Home: FC<Props> = (props) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(resetMeasurements());
    dispatch(clearGrid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = useCallback(
    (type: ElevationType) => {
      navigate(`${PATH_ELEV_MEASUREMENTS}?type=${type}`);
    },
    [navigate]
  );

  return (
    <div data-test="Home">
      <SectionContainer customClassNames="flex flex-col items-center gap-12 mt-20 pb-10">
        <div className="flex flex-col justify-end gap-6 text-center">
          <Heading text="Design your system" />
          <Subheading text="How many elevations do you want your solar panels on?" />
        </div>
        <div className="flex flex-col md:flex-row justify-between gap-12">
          {ELEVATIONS.map((elevation) => (
            <HouseTypeButton key={elevation} onClick={handleClick} type={elevation} />
          ))}
        </div>
        <div className="w-[90%] lg:w-[60%] m-auto text-[14px] text-center">
          Please seek guidance from your roofer or merchant if you have a north-facing roof or a pitch below 15 degrees.
        </div>
      </SectionContainer>
    </div>
  );
};

Home.propTypes = {};

export default Home;
