import { FC, useEffect, useMemo, useRef, useState } from 'react';
import './HouseModel.styles.scss';

import HouseSlice from 'assets/images/house-slice.png';
import { TwoDimSolarPanel } from 'assets/images';

import { IGrid } from 'types/grid';

type Props = {
  grid: IGrid | undefined;
};

const HouseModel: FC<Props> = ({ grid }) => {
  const WIDTH = grid?.[0]?.length || 0;
  const HEIGHT = grid?.length || 0;
  const roofRef = useRef<HTMLDivElement>(null);
  const [roofWidth, setRoofWidth] = useState<null | number>(null);

  useEffect(() => {
    if (roofRef?.current) {
      setRoofWidth(roofRef?.current.clientWidth);
    }
  }, []);

  const styles = useMemo(() => {
    // if (WIDTH < 4) {
    //   return { container: { maxWidth: '160px' }, roof: { height: '120px' }, house: { top: '118px' } };
    // }
    // if (WIDTH < 8) {
    //   return { container: { maxWidth: '300px' }, roof: { height: '180px' }, house: { top: '178px' } };
    // }
    // if (WIDTH >= 8) {
    //   if (HEIGHT <= 8) return { container: { maxWidth: '100%' }, roof: { height: '260px' }, house: { top: '255px' } };
    //   if (HEIGHT <= 16) return { container: { maxWidth: '100%' }, roof: { height: '260px' }, house: { top: '255px' } };
    //   if (HEIGHT > 16) return { container: { maxWidth: '100%' }, roof: { height: '260px' }, house: { top: '255px' } };
    // } else if (WIDTH <= 16) {
    //   if (HEIGHT <= 8) return { container: { maxWidth: '100%' }, roof: { height: '260px' }, house: { top: '255px' } };
    //   if (HEIGHT <= 16) return { container: { maxWidth: '100%' }, roof: { height: '260px' }, house: { top: '255px' } };
    //   if (HEIGHT > 16) return { container: { maxWidth: '100%' }, roof: { height: '260px' }, house: { top: '255px' } };
    // } else if (WIDTH > 16) {
    //   // if (HEIGHT <= 8) return <ThreeByOneModel />;
    //   if (HEIGHT <= 16) return { container: { maxWidth: '100%' }, roof: { height: '260px' }, house: { top: '255px' } };
    //   if (HEIGHT > 16) return { container: { maxWidth: '100%' }, roof: { height: '260px' }, house: { top: '255px' } };
    // } else return { container: { maxWidth: '100%' }, roof: { height: '260px' }, house: { top: '255px' } };
    return { container: { maxWidth: '100%' }, roof: { height: '260px' }, house: { top: '255px' } };
  }, []);

  return (
    <div data-testid="HouseModel" className="HouseModel relative w-full scale-[0.9]" style={styles.container}>
      <div
        data-testid="roof"
        className="house-roof absolute top-0 left-[0%] w-full p-4"
        style={styles.roof}
        ref={roofRef}
      >
        <div className="grid-wrapper w-full flex flex-col h-full mx-auto">
          {grid?.map((row, gridIndex) => {
            const gridLength = grid.length;
            // const rectangleWidth = (roofWidth || 0) / row.length;
            // const aspectRatio = 260 / (row.length * (100 / 630));
            return (
              <div className="grid-row flex justify-start " style={{ height: `calc(100% / ${gridLength})` }}>
                {row.map((item, rowIndex) => {
                  const rowLength = row.length;

                  const [y, x] = item.position;

                  const isSelected = item?.selected;
                  // const takeOffPercentage = gridLength > 6 ? 1 : 2;
                  return (
                    <div
                      className={isSelected ? 'border-[0.5px] border-gray-400 border-opacity-5' : ''}
                      style={{
                        height: 'auto',
                        width: `calc(100% / ${rowLength})`,
                        maxWidth: '95px',
                      }}
                    >
                      {isSelected && (
                        <img
                          src={TwoDimSolarPanel}
                          alt="2d-solar-panel"
                          className={`2d-panel h-[100%] w-[100%] pointer-events-none`}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <img src={HouseSlice} width="100%" alt="house-img" className="relative z-[-1]" style={styles.house}></img>
    </div>
  );
};

HouseModel.propTypes = {};

export default HouseModel;
