import { FC } from 'react';

const ChevronDown: FC<{ colour: string, width: number }> = ({ colour, width }) => {
  return (
    <svg
      id="Group_1558"
      data-name="Group 1558"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 30 30"
    >
      <circle
        id="Ellipse_2"
        data-name="Ellipse 2"
        cx="15"
        cy="15"
        r="15"
        transform="translate(0 0)"
        fill={colour === "white" ? '#fff' : "#000"}
      />
      <path
        id="Path_4"
        data-name="Path 4"
        d="M5.288,6.3.21,1.225A.717.717,0,1,1,1.225.21L5.288,4.273,9.35.21a.717.717,0,0,1,1.015,1.015Z"
        transform="translate(9.711 12.438)"
        fill={colour === "white" ? '#3F3F3F' : "#fff"}
      />
    </svg>
  );
};

interface ChevronBtnProps {
  onClick?: () => void;
  open: boolean;
  colour?: 'black' | 'white';
  width?: number;
}

const ChevronBtn: FC<ChevronBtnProps> = ({ onClick, open, colour = 'black', width = 30 }) => {
  return (
    <button onClick={onClick} data-testid="ChevronBtn" className={`transition-all cursor-pointer active:scale-90 `}>
      <div className={`transition-all ${open ? 'rotate-180' : ''} hover:text-gray-700`}>
        <ChevronDown colour={colour} width={width} />
      </div>
    </button>
  );
};

ChevronBtn.propTypes = {};

export default ChevronBtn;
