import { FC, ReactNode } from 'react';
import { cc } from 'utilities/styles/styles.util';

type Props = {
  borderLeft?: boolean;
  paddingRight?: boolean;
  paddingLeft?: boolean;
  children: ReactNode;
};

const TableSystemCell: FC<Props> = ({ children, paddingLeft, paddingRight, borderLeft }) => {
  return (
    <div
      data-testid="TableSystemCell"
      className={cc([
        'w-full',
        paddingLeft ? 'lg:pl-10' : '',
        paddingRight ? 'lg:pr-10' : '',
        borderLeft ? 'border-l-[1px] border-gray-200' : '',
      ])}
    >
      {children}
    </div>
  );
};

TableSystemCell.propTypes = {};

export default TableSystemCell;
