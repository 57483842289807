import { FC } from 'react';

import { mediaQueries } from 'constants/responsive';
import { useMediaQuery } from 'hooks/useResponsive';

import { cc } from 'utilities/styles/styles.util';

type Props = {
  active: boolean;
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const SwitchElevationTab: FC<Props> = ({ active, text, onClick }) => {
  const [isTablet] = useMediaQuery([mediaQueries.lgTablet]);

  const t = isTablet ? text.split(' ')[1] : text;

  return (
    <button
      onClick={onClick}
      data-testid="SwitchElevationTab"
      className={cc([
        'group rounded-t-20 px-7 py-3  transition-all font-bold text-xl  lg:text-23 bg-light-grey text-black',
        !active ? 'opacity-50 ' : '',
      ])}
    >
      <p className="group-active:scale-95 duration-100 ">{t}</p>
    </button>
  );
};

export default SwitchElevationTab;
