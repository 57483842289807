import { FC } from 'react';

import { Heading } from 'components/typography/Heading';
import { DownloadResource } from 'components/features/ResourcesSection/components/DownloadResource';
import { ResourceGrid } from 'components/features/ResourcesSection/components/ResourceGrid';
import { FaqAccordion } from 'components/elements/FaqAccordion';
import { FAQItem } from 'components/elements/FaqAccordion/components/FaqAccordionItem';
import { ResourceGridItemType } from 'components/features/ResourcesSection/components/ResourceGrid/components/ResourceGridItem';
import { SectionContainer } from 'components/layout/SectionContainer';
import { ResourceContainer } from 'components/layout/ResourceContainer';
import { DownloadBtn } from 'components/buttons/DownloadBtn';

import './ResourcesSection.styles.scss';

type ResourceGridListItem = {
  title: string;
  items: ResourceGridItemType[];
}[];

type Props = {
  freeResourcesList: ResourceGridListItem;
  faqs: FAQItem[];
  resources: ResourceGridListItem;
  productsList: ResourceGridListItem;
  formSubmitted?: boolean;
  handleDownload?: (resource_url: string) => Promise<void>;
  handleBack(): void;
  handleGetResources(): void;
};

const ResourcesSection: FC<Props> = ({
  faqs,
  resources,
  productsList,
  formSubmitted,
  handleGetResources,
  handleDownload,
}) => {
  return (
    <div
      data-testid="ResourcesSection"
      className="ResourcesSection bg-white p-10 flex flex-col gap-0 md:gap-10 text-black"
    >
      <SectionContainer customClassNames="">
        {formSubmitted && (
          <div className="">
            <ResourceContainer>
              <Heading text="Thank you - here’s your free resources" />
            </ResourceContainer>
            <DownloadResource handleDownload={handleDownload} />
          </div>
        )}

        {resources.map(({ title, items }) => (
          <ResourceGrid list={items} title={title} handleDownload={handleDownload} showDownloadButton />
        ))}

        {!formSubmitted && (
          <div className="my-10 flex justify-center gap-6">
            <DownloadBtn onClick={handleGetResources} text="Download your product list and access resources here" />
          </div>
        )}
        {/* 
        {formSubmitted &&
          productsList.map(({ title, items }) => (
            <ResourceGrid list={items} title={title} handleDownload={handleDownload} />
          ))} */}
        {!!faqs?.length && <FaqAccordion list={faqs} />}
      </SectionContainer>
    </div>
  );
};

ResourcesSection.propTypes = {};

export default ResourcesSection;
