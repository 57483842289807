import { FC } from 'react';
import { WarningRed } from 'assets/images';

type Props = {
  message: string;
};

const FormError: FC<Props> = ({ message }) => {
  return (
    <div className={' text-red-400 flex items-baseline gap-2 mt-1'}>
      <img className=" h-[15px] my-auto  pb-[1.35px] scale-105" src={WarningRed} alt="error" />
      <p data-testid="FormError" className="text-[14px] mb-[-3px]">
        {message}
      </p>
    </div>
  );
};

FormError.propTypes = {};

export default FormError;
