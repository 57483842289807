import { FC, ReactNode } from 'react';
import './SectionContainer.styles.scss';
import { ElementClassName } from 'types/general';

type Props = {
  children?: ReactNode;
  customClassNames?: ElementClassName | string;
};

const SectionContainer: FC<Props> = ({ children, customClassNames = '' }) => {
  return (
    <div
      data-testid="SectionContainer"
      className={`SectionContainer w-full min-h-100 mx-auto max-w-[1440px] md:p-8 ${customClassNames}`}
    >
      {children}
    </div>
  );
};

SectionContainer.propTypes = {};

export default SectionContainer;
