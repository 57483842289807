import { FC, memo } from 'react';

import { TableSingleElevation } from './components/TableSingleElevation';
import { SwitchElevationTab } from './components/SwitchElevationTab';
import { Heading } from '../../typography/Heading';

import { useMediaQuery } from 'hooks/useResponsive';

import { mediaQueries } from 'constants/responsive';

import { IComponentSpecElevation, IUnits } from 'types/api';

import './ElevationsBreakdown.styles.scss';

type Props = {
  elevations: IComponentSpecElevation[];
  elevationIndex: number;
  setElevationIndex(index: number): void;
  units: IUnits;
};

const ElevationsBreakdown: FC<Props> = memo(({ elevations, elevationIndex, setElevationIndex, units }) => {
  const [isTablet] = useMediaQuery([mediaQueries.smlTablet]);
  const title = `Elevation ${elevationIndex + 1}`;

  if (!elevations?.length) return null;

  return (
    <>
      {isTablet && <Heading variant="md" text="Elevations" className="mb-6" />}
      <div data-testid="ElevationsBreakdown" className="elevations-breakdown">
        <div>
          {elevations.map((el, i) => (
            <SwitchElevationTab
              active={elevationIndex === i}
              onClick={() => setElevationIndex(i)}
              text={el?.elevation_name || `Elevation ${i + 1}`}
              key={`Elevation ${i + 1}`}
            />
          ))}
        </div>
        <div className="bg-light-grey px-8 py-2 __systems-wrapper">
          <TableSingleElevation key={title} systems={elevations[elevationIndex].systems} units={units} />
        </div>
      </div>
    </>
  );
});

ElevationsBreakdown.propTypes = {};

export default ElevationsBreakdown;
