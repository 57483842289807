import { FC } from 'react';
import './ResourceGrid.styles.scss';
import { ResourceGridItem, ResourceGridItemType } from './components/ResourceGridItem';
import { ResourceContainer } from 'components/layout/ResourceContainer';

type Props = {
  list: ResourceGridItemType[];
  title?: string;
  noBorder?: boolean;
  handleDownload?: (resource_url: string, resource_name?: string) => Promise<void>;
  showDownloadButton?: boolean;
};

const ResourceGrid: FC<Props> = ({ list, title, noBorder, handleDownload, showDownloadButton }) => {
  return (
    <ResourceContainer customClasses={noBorder ? 'border-none' : ''}>
      {title && <h1 className="mt-6 md:mt-0 mb-6  font-bold text-2xl">{title}</h1>}
      <div className="grid md:grid-cols-3 lg:grid-cols-5  gap-6">
        {list.map(({ img_url, resource_url, resource_name, text, local_url, external_url, btnText }, index) => (
          <ResourceGridItem
            img_url={img_url}
            resource_url={resource_url}
            resource_name={resource_name}
            local_url={local_url}
            external_url={external_url}
            text={text}
            handleDownload={handleDownload}
            showDownloadButton={showDownloadButton}
            btnText={btnText}
            key={`resource_grid_item_${index}`}
          />
        ))}
      </div>
    </ResourceContainer>
  );
};

ResourceGrid.propTypes = {};

export default ResourceGrid;
