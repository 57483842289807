import axiosInstance from 'services/axios';

export const downloadFile = async (url: string, filename: string, data: any) => {
  try {
    const link = document.createElement('a');
    const targetId = "downloadDiv"+Date.now();
    link.target = '_blank';
    link.id = targetId;  
    link.download = filename ? filename : 'File.zip';
    // const data = { customer_data: customer_data, config_data: CONFIG_DATA };
    const res = (await axiosInstance.post(url, data, {
      responseType: 'blob',
    })) as BlobPart;
    link.href = URL.createObjectURL(new Blob([res]));
    const removeDownloadLink = document.getElementById(targetId);
    removeDownloadLink?.addEventListener('click',function(){  // if this function is defined outside it won't work because divElement will be out of its scope
      document.body.removeChild(link);
    });
    link.click();
  } catch (err) {
    console.log('🚀  err:', err);
  }
};
