import { FC, ReactNode, Ref, forwardRef } from 'react';
import './ExpandableContainer.styles.scss';
import { ElementClassName } from 'types/general';

type Props = {
  isOpen: boolean;
  children: ReactNode;
} & ElementClassName;

const ExpandableContainer: FC<Props & { ref?: React.Ref<HTMLDivElement> }> = forwardRef(
  ({ isOpen, children, className }, ref: Ref<HTMLDivElement>) => {
    return (
      <div className={`expandable-container ${isOpen ? 'open' : ''}`}>
        <div ref={ref} className={`__content scroll-smooth ${className || ''}`}>
          {children}
        </div>
      </div>
    );
  }
);

ExpandableContainer.propTypes = {};

export default ExpandableContainer;
