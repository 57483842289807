import { FC } from 'react';
import './DownloadResource.styles.scss';

import { ResourceGrid } from '../ResourceGrid';
import { SolarResSix } from 'assets/images';
import BOM from 'assets/images/bom_icon.jpg';

type Props = {
  handleDownload?: (resource_url: string) => Promise<void>;
};

const DownloadResource: FC<Props> = ({ handleDownload }) => {
  return (
    <ResourceGrid
      list={[
        {
          text: 'Bill of Materials',
          img_url: BOM,
          resource_url: '/complete-order',
        },

        {
          resource_url: `${window.location.origin}/pdf/UK_MKT_ROF_DOC_Solar_installation_manual.pdf`,
          resource_name: `UK_MKT_ROF_DOC_Solar_installation_manual.pdf`,
          local_url: true,
          text: 'Solar installation manual',
          img_url: SolarResSix,
        },
      ]}
      title="Product List"
      handleDownload={handleDownload}
    />
  );
};

DownloadResource.propTypes = {};

export default DownloadResource;
