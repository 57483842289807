import { string, bool } from 'yup';
import { validate as validateEmail } from 'email-validator';

export const validation = {
  measurements: {
    height: string()
      .required('Length is a required field.')
      .test('Is a positive number', 'You must enter a positive value', (value) => Number(value) > 0),
    width: string()
      .required('Width is a required field.')
      .test('Is a positive number', 'You must enter a positive value', (value) => Number(value) > 0),
    units: string().required('Units is a required field.'),
  },
  download_form: {
    first_name: string().required('First name is a required field.'),
    last_name: string().required('Last name is a required field.'),
    email: string()
      .test('Email validation', 'Must enter a valid email address', (value) => {
        return validateEmail(value);
      })
      .required('You must enter an email'),
    mobile: string(),
    company_name: string(),
    business_type: string().required('Business type is a required field.'),
    post_code: string().required('Postcode is a required field.'),
    subscribed: bool(),
    privacy_policy: bool().oneOf([true], 'Field must be checked'),
    robot: bool().oneOf([true], 'Field must be checked'),
  },
};
