import { FC, ReactNode } from 'react';
import './TwoColumnContainer.styles.scss';

import { ElementClassName } from 'types/general';

type Props = {
  leftComponent: ReactNode;
  rightComponent: ReactNode;
  customClasses?: ElementClassName | string;
  my?: ElementClassName | string;
  rightContainerClasses?: ElementClassName | string;
  mxAutoRight?: boolean;
};

const TwoColumnContainer: FC<Props> = ({
  leftComponent,
  rightComponent,
  customClasses,
  my = 'md:my-20',
  mxAutoRight = true,
  rightContainerClasses = '',
}) => {
  return (
    <div
      data-testid="TwoColumnContainer"
      className={`TwoColumnContainer flex flex-col md:flex-row gap-8 mt-5 ${my} ${customClasses}`}
    >
      <div className="md:w-[50%]">{leftComponent}</div>
      <div className={`md:w-[50%] relative ${mxAutoRight ? 'mx-auto' : ''} ${rightContainerClasses}`}>
        {rightComponent}
      </div>
    </div>
  );
};

TwoColumnContainer.propTypes = {};

export default TwoColumnContainer;
