import { FC, RefObject, useCallback, useEffect, useRef, useState } from 'react';

import { CustomSlider } from './components/CustomSlider';

import { useEventListener } from 'hooks/useEventListener';
import useDeviceDetect from 'hooks/useDeviceDetect';

import './ScrollController.styles.scss';

type Props = {
  elRef: RefObject<HTMLDivElement>;
};

const ScrollController: FC<Props> = ({ elRef }) => {
  const [progressValue, setProgressValue] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);

  const isMobile = useDeviceDetect();

  const isScrolling = useRef<boolean>(false);
  const isSliding = useRef<boolean>(false);

  const onElementScrollEnd = useCallback((e: Event) => {
    isScrolling.current = true;
    const el = e.target as HTMLDivElement;
    const scrollPercentage = Math.floor((100 * el.scrollLeft) / (el.scrollWidth - el.clientWidth));
    if (!isSliding.current) setProgressValue(scrollPercentage);
    isSliding.current = false;
  }, []);

  const onResize = useCallback(() => {
    if (elRef?.current) {
      const scrollWidth = elRef.current.scrollWidth;
      const clientWidth = elRef.current.clientWidth;
      setShow(scrollWidth - clientWidth > 30);
    }
  }, [elRef]);

  useEventListener('scrollend', onElementScrollEnd, elRef);

  useEventListener('resize', onResize);

  const onCustomSliderChanger = useCallback(
    (v: number) => {
      isSliding.current = true;
      if (elRef.current) {
        setProgressValue(v);
        const newScrollPos = elRef?.current?.scrollWidth ? (elRef?.current?.scrollWidth / 100) * v : 0;
        elRef.current.scrollLeft = newScrollPos;
      }
    },
    [elRef]
  );

  useEffect(() => {
    const targetElement = elRef?.current;
    if (targetElement && isMobile) {
      targetElement.addEventListener('scroll', onElementScrollEnd);
    }
    return () => {
      if (targetElement) {
        targetElement.removeEventListener('scroll', onElementScrollEnd);
      }
    };
  }, [isMobile, onElementScrollEnd, elRef]);

  useEffect(() => {
    onResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return show ? (
    <div className="scroll-controller-wrapper relative w-full flex justify-center">
      <div className="flex w-full items-center gap-4">
        <CustomSlider value={Math.floor(progressValue)} setValue={onCustomSliderChanger} />
      </div>
    </div>
  ) : null;
};

export default ScrollController;
