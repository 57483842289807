import { useRef, memo, FC } from 'react';

import { Warning } from 'assets/images';

import './GridWarningInfo.styles.scss';

type Props = {
  text?: string;
};

// type Side = 'left' | 'right';

const GridWarningInfo: FC<Props> = memo(({ text = 'You must place a solar panel here' }) => {
  // const [scope, animate] = useAnimate();
  // const clickedRef = useRef<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  // const [side, setSide] = useState<Side>('right');

  // const animateHover = () => {
  //   wrapperRef?.current?.classList.add('zInd');
  //   animate(scope.current, { scaleX: 1, x: side === 'right' ? 'calc(-100% + 2rem)' : '0px' });
  //   animate('p', { opacity: 1 }, { delay: 0.2 });
  //   clickedRef.current = true;
  // };
  // const animateLeave = () => {
  //   animate('p', { opacity: 0 });
  //   animate(scope.current, { scaleX: 0, x: side === 'right' ? 'calc(-100% + 2rem)' : '0px' }, { delay: 0.2 });
  //   clickedRef.current = false;
  //   setTimeout(() => {
  //     wrapperRef?.current?.classList.remove('zInd');
  //   }, 150);
  // };

  // const onClick = () => {
  //   if (!clickedRef.current) {
  //     animateHover();
  //   } else animateLeave();
  // };

  // useEffect(() => {
  //   const middle = window.innerWidth / 2;
  //   const pos = wrapperRef.current?.getBoundingClientRect()?.x;
  //   if (pos && pos >= middle) setSide('right');
  //   else setSide('left');
  // }, []);

  return (
    <div
      // onMouseOver={animateHover}
      // onMouseOut={animateLeave}
      // onClick={onClick}
      data-testid=" grid-warning-info"
      className="grid-warning-info relative w-[2rem] h-[2rem]"
      ref={wrapperRef}
    >
      {/* <motion.div
        ref={scope}
        initial={{ scaleX: 0 }}
        // animate={{ scaleX: 0, x: side === 'right' ? 'calc(-100% + 2rem)' : '0px' }}
        className={cc([
          'absolute top-[1.5px]  bg-wb-red px-4  py-1  rounded-full max-w-[80vw] w-max pointer-events-none',
          side === 'right' ? 'origin-right  pr-10 left-0 ' : 'origin-left pl-10 left-0',
        ])}
      >
        <motion.p className="text-white text-[14px]" initial={{ opacity: 0 }}>
          {text}
        </motion.p>
      </motion.div> */}
      <div className=" origin-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] pointer-events-none">
        <img src={Warning} className="warning-icon  h-[1.25rem]" alt="grid-warning" />
      </div>
    </div>
  );
});

export default GridWarningInfo;
