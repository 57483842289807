import { FC, MouseEventHandler, memo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  addDragPosition,
  handleCellClick,
  onDragEnd,
  onDragStart,
  setWarningsAndValidSystems,
} from 'store/slices/configurator/configurator.slice';

import { GridWarningInfo } from '../GridWarningInfo';

import { TwoDimSolarPanel } from 'assets/images';

import { GridItem } from 'types/grid';
import { AppDispatch, RootState } from 'types/store';

import './ConfiguratorGridCell.styles.scss';

const ConfiguratorGridCell: FC<GridItem> = memo(({ position, selected, warning, dragged }) => {
  const dispatch: AppDispatch = useDispatch();

  const thisDragged = useRef<boolean>(false);

  const isDragging = useSelector((state: RootState) => state.configurator.isDragging);

  const onClick = useCallback(() => {
    dispatch(handleCellClick(position));
    dispatch(setWarningsAndValidSystems());
  }, [dispatch, position]);

  const dragStart = useCallback(() => dispatch(onDragStart()), [dispatch]);

  const dragEnd = useCallback(() => {
    dispatch(onDragEnd());
    dispatch(setWarningsAndValidSystems());
    thisDragged.current = false;
  }, [dispatch]);

  const addDragPos = useCallback(() => dispatch(addDragPosition(position)), [dispatch, position]);

  const handleDragStart: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      if (!thisDragged.current) {
        thisDragged.current = true;
        dragStart();
        addDragPos();
      }
    },
    [addDragPos, dragStart]
  );

  const handleDragOver: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      if (!thisDragged.current && isDragging) {
        thisDragged.current = true;
        addDragPos();
      }
    },
    [addDragPos, isDragging]
  );

  const handleMouseLeave = () => {
    thisDragged.current = false;
  };

  return (
    <button
      onClick={onClick}
      onMouseOver={handleDragOver}
      onMouseUp={dragEnd}
      onMouseDown={handleDragStart}
      onMouseLeave={handleMouseLeave}
      className={`configurator-grid-cell w-full h-full border-[1px]  flex items-center justify-center ${
        dragged ? 'border-wb-red-lighter' : 'border-gray-400'
      }`}
    >
      {dragged ? (
        <div className="h-full w-full bg-wb-red-lighter opacity-75"></div>
      ) : selected ? (
        <img
          src={TwoDimSolarPanel}
          alt="2d-solar-panel"
          className={`2d-panel h-[100%]  w-[100%] pointer-events-none`}
        />
      ) : warning ? (
        <GridWarningInfo />
      ) : null}
    </button>
  );
});

ConfiguratorGridCell.propTypes = {};

export default ConfiguratorGridCell;
