import React from 'react';

type Props = {
  className?: string;
};

const KWp: React.FC<Props> = ({ className = '' }) => {
  return (
    <span data-testid="KWp" className={`text-[11px] lg:text-[13px] ${className}`}>
      kWp
    </span>
  );
};

KWp.propTypes = {};

export default KWp;
