import { FC, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { array, object } from 'yup';

import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { ElevationSubForm } from '../../elements/ElevationSubForm';
import { ElevationButton } from 'components/buttons/ElevationButton';
import { ActionBtn } from 'components/buttons/ActionBtn';

import { ElevationForm, ElevationType } from 'types/general';

import { validation } from 'validation/validation';
import { useYupValidationResolver } from 'hooks/useYupResolver';
import { setMeasurements } from 'store/slices/measurements/measurements.slice';

import { findInvalidElevations, findLimitExceededElevations } from 'utilities/helpers/findInvalidElevation';
import { PATH_ELEV_CONFIGURE } from 'constants/paths';

import './MeasurementsForm.styles.scss';

import axiosInstance from 'services/axios';

import { AppDispatch, RootState } from 'types/store';

type Props = {
  type: ElevationType;
};

const validationSchema = object({
  elevations: array().of(
    object().shape({
      height: validation.measurements.height,
      width: validation.measurements.width,
    })
  ),
  units: validation.measurements.units,
});

const MeasurementsForm: FC<Props> = ({ type }) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const resolver = useYupValidationResolver(validationSchema);

  const measurements = useSelector((state: RootState) => state.measurements);

  const prevMeasurements = measurements?.measurements?.elevations.map(({ height, width, elevation_name }) => ({
    height,
    width,
    elevation_name,
  }));
  const prevUnits = measurements?.measurements?.units;

  const hasPrevMeasurements = !!prevMeasurements?.length;

  const noCurrentDefault = {
    elevations: [{ height: '', width: '', elevation_name: 'Elevation 1' }],
    units: 'mm',
  } as ElevationForm;

  const defaultVals = hasPrevMeasurements ? { elevations: prevMeasurements, units: prevUnits } : noCurrentDefault;

  const methods = useForm({
    defaultValues: defaultVals,
    mode: 'onChange',
    resolver,
  });

  const scrollContainer = useRef<HTMLDivElement>(null);

  const { control, setError } = methods;

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'elevations',
  });

  const onElevationAdd = useCallback(() => {
    append({
      elevation_name: `Elevation ${fields.length + 1}`,
      width: '',
      height: '',
    });
    const height = scrollContainer?.current?.scrollHeight || 0;
    scrollContainer?.current?.scrollTo(0, height);
  }, [append, fields.length]);

  const handleSubmit = useCallback(
    async (data: ElevationForm) => {
      let shouldFetchNew = true;

      const emptyElevation = data?.elevations?.find(({ elevation_name }) => !elevation_name);
      if (emptyElevation) {
        setError(`elevation_${data?.elevations?.indexOf(emptyElevation) + 1}`, {
          message: 'Please enter a name for the elevation.',
        });
        return;
      }

      if (hasPrevMeasurements) {
        const { getValues } = methods;
        const prev = JSON.stringify(prevMeasurements);
        const currentForm = JSON.stringify(getValues().elevations);
        const currentUnits = getValues().units;

        if (prev === currentForm && currentUnits === prevUnits) {
          shouldFetchNew = false;
        }
      }

      if (shouldFetchNew) {
        try {
          const res = await axiosInstance.post('/system-spec', data);
          const resData = res?.data;

          const elevations = resData?.elevations;

          const invalidElevations = findInvalidElevations(elevations || []);
          const limitExceededElevations = findLimitExceededElevations(elevations || []);

          // is not valid, set errors
          if (invalidElevations.length || limitExceededElevations.length) {
            if (invalidElevations.length) {
              invalidElevations.forEach((elevationIndex) => {
                setError(`elevation_${elevationIndex + 1}`, {
                  message: 'The elevations measurements are too small to fit a system.',
                });
              });
            }
            if (limitExceededElevations.length) {
              limitExceededElevations.forEach((elevationIndex) => {
                setError(`elevation_${elevationIndex + 1}`, {
                  message: 'Please contact us to discuss in detail the system due to the size required.',
                });
              });
            }
          } else {
            dispatch(setMeasurements(resData));
            navigate(`${PATH_ELEV_CONFIGURE}/1`);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        navigate(`${PATH_ELEV_CONFIGURE}/1`);
      }
    },
    [dispatch, navigate, setError, hasPrevMeasurements, prevMeasurements, prevUnits, methods]
  );

  const onBackAction = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div
      data-testid="MeasurementsForm"
      className="MeasurementsForm max-w-[310px] 
      flex flex-col justify-between mx-auto md:mx-0"
    >
      <FormProvider {...methods}>
        <div className={`${type === 'multi' ? 'md:overflow-y-auto' : ''}`} ref={scrollContainer}>
          {fields.map((field, index) => {
            return (
              <div className="my-4" key={`${field.id}_${index}`}>
                <ElevationSubForm
                  elevation_name={`elevations[${index}].elevation_name`}
                  heightName={`elevations[${index}].height`}
                  widthName={`elevations[${index}].width`}
                  elevationFormName={`elevation_${index + 1}`}
                  unitsName={`units`}
                  key={`${field.id}_${index}`}
                  onRemove={
                    type === 'multi' && index
                      ? () => {
                          remove(index);
                        }
                      : undefined
                  }
                  expand={index === 0}
                  type={type}
                />
              </div>
            );
          })}
        </div>
        {type === 'multi' ? (
          <div className="my-4">
            <ElevationButton onClick={onElevationAdd} />
          </div>
        ) : null}
        <div className="flex gap-4 mt-6 pl-2 justify-center md:justify-start">
          <ActionBtn variant="prev" onClick={onBackAction} />
          <ActionBtn variant="submit" onClick={methods.handleSubmit(handleSubmit as SubmitHandler<ElevationForm>)} />
        </div>
      </FormProvider>
    </div>
  );
};

MeasurementsForm.propTypes = {};

export default MeasurementsForm;
