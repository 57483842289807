import { FC, ReactNode } from 'react';
import './TableProductRowWrapper.styles.scss';

type Props = {
  children: ReactNode;
  borderBottom?: boolean;
};

const TableProductRowWrapper: FC<Props> = ({ children, borderBottom }) => {
  return (
    <div
      data-testid="TableProductRowWrapper"
      className={`table-product-row-wrapper min-w-[max-content] flex flex-nowrap lg:grid gap-4 ${
        borderBottom ? 'border-bottom' : ''
      }`}
    >
      {children}
    </div>
  );
};

export default TableProductRowWrapper;
