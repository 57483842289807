import { FC } from 'react';

import { TableProductHeaderRow } from '../TableProductHeaderRow';
import { TableProductItemRow } from '../TableProductItemRow';

import { IProductRowData } from '../../types';

import './TableProductGrid.styles.scss';

type Props = {
  components: IProductRowData[];
};

const TableProductGrid: FC<Props> = ({ components }) => {
  return (
    <div data-testid="TableProductGrid" className="">
      <TableProductHeaderRow />
      {components?.map((c, i) => {
        return <TableProductItemRow borderBottom={i !== components?.length - 1} data={c} key={`table_product_${i}`} />;
      })}
    </div>
  );
};

TableProductGrid.propTypes = {};

export default TableProductGrid;
