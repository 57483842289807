import { IElevationMeasurementsOut } from 'types/api';

export function findInvalidElevations(arr: IElevationMeasurementsOut[]) {
  const invalidElevations = [];

  for (let i = 0; i < arr.length; i++) {
    const { max_rows, max_columns } = arr[i];
    if (max_rows < 1 || max_columns < 2) {
      invalidElevations.push(i);
    }
  }

  return invalidElevations;
}

export function findLimitExceededElevations(arr: IElevationMeasurementsOut[]) {
  const invalidElevations = [];

  for (let i = 0; i < arr.length; i++) {
    const { limit_exceeded } = arr[i];
    if (limit_exceeded) {
      invalidElevations.push(i);
    }
  }

  return invalidElevations;
}
