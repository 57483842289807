import { FC } from 'react';

import { cc } from 'utilities/styles/styles.util';

type Props = { variant?: 'sm' | 'md' | 'lg'; text: string; color?: string; bold?: boolean };

const Subheading: FC<Props> = ({ variant = 'lg', text, color = 'medium-grey', bold }) => {
  const textVariants = {
    sm: 'text-sm lg:text-md',
    md: 'text-lg lg:text-21',
    lg: 'text-xl lg:text-23',
  };
  return (
    <p data-testid="Subheading" className={cc([`text-${color} ${bold ? 'font-bold' : ''}`, textVariants[variant]])}>
      {text}
    </p>
  );
};

export default Subheading;
