import { FC } from 'react';
import './ConfiguratorGridRow.styles.scss';

import { GridItem } from 'types/grid';
import { ConfiguratorGridCell } from '../ConfiguratorGridCell';

type Props = {
  row: GridItem[];
};

const ConfiguratorGridRow: FC<Props> = ({ row }) => {
  return (
    <>
      {row?.map((item) => (
        <ConfiguratorGridCell key={JSON.stringify(item.position)} {...item} />
      ))}
    </>
  );
};

ConfiguratorGridRow.propTypes = {};

export default ConfiguratorGridRow;
