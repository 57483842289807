import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { TwoColumnContainer } from 'components/layout/TwoColumnContainer';
import { Heading } from 'components/typography/Heading';
import { DownloadReourcesHere } from 'components/elements/DownloadReourcesHere';
import { ElevationPower } from 'components/elements/ElevationPower';
import { SectionContainer } from 'components/layout/SectionContainer';
import { ResourcesSection } from 'components/features/ResourcesSection';
import { DownloadResourcesForm } from 'components/features/ResourcesSection/components/DownloadResourcesForm';
import { ElevationsBreakdown } from 'components/features/ElevationsBreakdown';
import { LoadingSpinner } from 'components/elements/LoadingSpinner';
import { FormError } from 'components/form/FormError';
import { ActionBtn } from 'components/buttons/ActionBtn';
import { HouseModel } from 'components/elements/HouseModel';
import { Subheading } from 'components/typography/Subheading';

import { MOCK_RESOURCE_ITEMS } from './mockdata';

import { RootState } from 'types/store';
import { CustomerFormType, IApiResponse, IComponentSpecOut } from 'types/api';

import { addSystemPower } from 'utilities/helpers/addSystemPower';
import { downloadFile } from 'utilities/helpers/downloadFile';

import axiosInstance from 'services/axios';

import { setCustomerData, setFormSubmitted, setResults } from 'store/slices/results/results.slice';

import { FAQS, PRODUCTS_LIST, RESOURCE_ITEMS } from './data';

import './Results.styles.scss';

type Props = {};

const Results: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDownloadResourcesModal, setOpenDownloadResourcesModal] = useState<boolean>(false);
  const [elevationIndex, setElevationIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<string>('');

  const results = useSelector((state: RootState) => state.results.results);
  const customer_data = useSelector((state: RootState) => state.results.customer_data);

  const formSubmitted = useSelector((state: RootState) => state.results.formSubmitted);

  const measurements = useSelector((state: RootState) => state.measurements.measurements);

  const elevationSelected = results?.elevations?.[elevationIndex];

  const grid = measurements?.elevations?.[elevationIndex]?.grid;

  const getResults = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = (await axiosInstance.post('/component-spec', measurements)) as IApiResponse;
      const results = res?.data as IComponentSpecOut;
      const validation_error = res?.validation_error;
      if (validation_error) {
        setValidationError(validation_error);
      }

      dispatch(setResults(results));
    } catch (err) {
      console.log('🚀 err:', err);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, measurements]);

  useEffect(() => {
    getResults();
  }, [getResults]);

  const scrollToResources = useCallback(() => {
    resourcesRef.current?.scrollIntoView();
  }, []);

  const handleDownloadResourceClick = useCallback(() => {
    if (customer_data && formSubmitted) {
      return scrollToResources();
    }
    setOpenDownloadResourcesModal(true);
  }, [customer_data, formSubmitted, scrollToResources]);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const resourcesRef = useRef<HTMLDivElement>(null);

  const onSubmitCallback = useCallback(
    (data: CustomerFormType) => {
      dispatch(setCustomerData(data));
      setOpenDownloadResourcesModal(false);
      dispatch(setFormSubmitted(true));
      scrollToResources();
    },
    [dispatch, scrollToResources]
  );

  const handleDownload = useCallback(
    async (resource_url: string = '/complete-order', file_name: string | undefined = 'order.zip') => {
      if (window.adobeDataLayer) {
        console.log("Downloading", file_name);
        window.adobeDataLayer.push({
            "event": "webDownloads",
            "downloads": [file_name]
        })
      }
      try {
        const data = resource_url === '/complete-order' ? { customer_data: customer_data, config_data: results } : {};
        await downloadFile(resource_url, file_name, data);
      } catch (err) {
        console.log('🚀  err:', err);
      }
    },
    [customer_data, results]
  );

  if (isLoading) {
    return (
      <SectionContainer customClassNames={'flex justify-center mt-6 md:mt-0'}>
        <LoadingSpinner color="black" customClasses="h-10 w-10 border-4" />
      </SectionContainer>
    );
  }

  if (!elevationSelected || !results) {
    return <></>;
  }

  const max_power = elevationSelected.max_power || 0;
  const { system_power } = addSystemPower(elevationSelected);

  return (
    <div data-testid="Results" className="Results">
      <SectionContainer customClassNames="px-4 !pb-0">
        <TwoColumnContainer
          my=""
          mxAutoRight={false}
          customClasses="mt-20 mb-20 md:mb-[40px]  overflow-y-hidden"
          rightContainerClasses={'RightContainer overflow-hidden mx-auto'}
          leftComponent={
            <div className="flex flex-col gap-10 text-center md:text-left ">
              <Heading text="Your in-roof solar solution" />
              <div className="max-w-[500px] flex flex-col gap-10 self-center md:self-start">
                <ElevationPower current={system_power} max={max_power} />
                <DownloadReourcesHere onClick={handleDownloadResourceClick} />
                <div className="my-1">
                  <ActionBtn variant="prev" onClick={handleBack} />
                </div>
              </div>
            </div>
          }
          rightComponent={
            <div className="flex flex-col">
              <HouseModel grid={grid} />
              <div className="absolute bottom-0 right-[30px]">
                <Subheading text="* This is for illustration purposes only" variant="sm" />
              </div>
            </div>
          }
        />
        {validationError && (
          <div className="my-2">
            <FormError message={validationError}></FormError>
          </div>
        )}
        <ElevationsBreakdown
          elevations={results.elevations}
          elevationIndex={elevationIndex}
          setElevationIndex={setElevationIndex}
          units={results.units}
        />
      </SectionContainer>
      <div id="resoucers-container" ref={resourcesRef}>
        <ResourcesSection
          formSubmitted={formSubmitted}
          freeResourcesList={[{ ...MOCK_RESOURCE_ITEMS[0], title: '' }]}
          faqs={[]}
          resources={RESOURCE_ITEMS}
          productsList={PRODUCTS_LIST}
          handleDownload={handleDownload}
          handleBack={handleBack}
          handleGetResources={handleDownloadResourceClick}
        />
      </div>
      {openDownloadResourcesModal && (
        <DownloadResourcesForm
          open={openDownloadResourcesModal}
          onClose={() => setOpenDownloadResourcesModal(false)}
          onSubmitCallback={onSubmitCallback}
        />
      )}
    </div>
  );
};

Results.propTypes = {};

export default Results;
