import { FC, useCallback, useState } from 'react';
import { ResourceImage } from 'components/features/ResourcesSection/components/ResourceGrid/components/ResourceImage';
import { ResourceText } from 'components/features/ResourcesSection/components/ResourceGrid/components/ResourceText';
import { DownloadBtn } from 'components/buttons/DownloadBtn';

import './ResourceGridItem.styles.scss';

export type ResourceGridItemType = {
  img_url: string;
  text: string;
  resource_url?: string;
  resource_name?: string;
  local_url?: boolean;
  external_url?: boolean;
  handleDownload?: (resource_url: string, resource_name?: string) => Promise<void>;
  showDownloadButton?: boolean;
  btnText?: string;
};

const ResourceGridItem: FC<ResourceGridItemType> = ({
  img_url,
  text,
  resource_url,
  resource_name,
  external_url,
  local_url,
  handleDownload,
  showDownloadButton = true,
  btnText = 'Download',
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = useCallback(async () => {
    if (resource_url && handleDownload) {
      setIsLoading(true);
      await handleDownload(resource_url, resource_name);
      setIsLoading(false);
    }
  }, [handleDownload, resource_name, resource_url]);
  return (
    <div data-testid="ResourceGridItem" className="ResourceGridItem flex flex-col gap-4 w-full md:max-w-[317px]">
      <ResourceImage src={img_url} alt={text} />
      <ResourceText text={text} />
      {resource_url && showDownloadButton && (
        <div className="mt-auto">
          <DownloadBtn
            onClick={handleClick}
            resource_url={resource_url}
            resource_name={resource_name}
            external_url={external_url}
            local_url={local_url}
            isLoading={isLoading}
            text={btnText}
          />
        </div>
      )}
    </div>
  );
};

ResourceGridItem.propTypes = {};

export default ResourceGridItem;
