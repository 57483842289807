import { FC } from 'react';

import { TableText } from 'components/typography/TableText';

import { TableProductCell } from '../TableProductCell';

import { TableProductRowWrapper } from '../TableProductRowWrapper';

import { IProductRowData } from '../../types';

type TableProductItemRowProps = {
  data: IProductRowData;
  borderBottom?: boolean;
};

const TableProductItemRow: FC<TableProductItemRowProps> = ({ data, borderBottom }) => {
  const { description, item_number, part_code, quantity, unit } = data;
  return (
    <TableProductRowWrapper borderBottom={borderBottom}>
      <TableProductCell variant="left">
        <TableText text={item_number} />
      </TableProductCell>
      <TableProductCell variant="left">
        <TableText text={part_code} />
      </TableProductCell>
      <TableProductCell variant="left">
        <TableText text={description} />
      </TableProductCell>
      <TableProductCell variant="left">
        <TableText text={unit} />
      </TableProductCell>
      <TableProductCell variant="centered">
        <TableText text={`${quantity}`} />
      </TableProductCell>
    </TableProductRowWrapper>
  );
};

TableProductItemRow.propTypes = {};

export default TableProductItemRow;
