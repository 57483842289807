import { FC } from 'react';
import './Select.styles.scss';

import { RHFErrorMessage, SelectOption } from 'types/form';
import { FormError } from '../FormError';

interface SelectProps {
  options: SelectOption[];
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  error?: RHFErrorMessage;
  label?: string;
}

const Select: FC<SelectProps> = ({ options, value, onChange, error, label }) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    onChange(selectedValue);
  };

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        {label ? <label className="input-label w-[30%]">{label}</label> : null}
        <select
          className="Select cursor-pointer w-[65%] rounded-lg bg-white py-1 px-2 bg-transparent outline-none"
          value={value}
          onChange={handleChange}
        >
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {error && <FormError message={error} />}
    </div>
  );
};

export default Select;
