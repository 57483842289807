import { FC } from 'react';
import { TableProductCell } from '../TableProductCell';
import { TableText } from '../../../../typography/TableText';
import { TableProductRowWrapper } from '../TableProductRowWrapper';

type TableProductHeaderRowProps = {};

interface TitleObj {
  title: string;
  cellVariant: 'left' | 'centered';
}

const titles: TitleObj[] = [
  { title: 'Item number', cellVariant: 'left' },
  { title: 'Part code', cellVariant: 'left' },
  { title: 'Product', cellVariant: 'left' },
  { title: 'Unit', cellVariant: 'left' },
  { title: 'Quantity', cellVariant: 'centered' },
];

const TableProductHeaderRow: FC<TableProductHeaderRowProps> = () => {
  return (
    <TableProductRowWrapper borderBottom>
      {titles.map((t, i) => {
        return (
          <TableProductCell variant={t.cellVariant} key={`${t.title}_${i}`}>
            <TableText text={t.title} variant="bold" />
          </TableProductCell>
        );
      })}
    </TableProductRowWrapper>
  );
};

TableProductHeaderRow.propTypes = {};

export default TableProductHeaderRow;
