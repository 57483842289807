import { FC, cloneElement } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

// guards

// layouts

// pages
import { Home } from '../components/pages/Home';
import { Measurements } from 'components/pages/Measurements';
import { Results } from 'components/pages/Results';
import { Configuration } from 'components/pages/Configuration';

// paths
import { PATH_ELEV_CONFIGURE, PATH_ELEV_MEASUREMENTS, PATH_ELEV_RESULTS } from 'constants/paths';

// config

// ----------------------------------------------------------------------

const Router: FC = () => {
  const elements = useRoutes([
    {
      path: '/',
      element: <Home />,
    },
    {
      path: PATH_ELEV_MEASUREMENTS,
      element: <Measurements />,
    },
    {
      path: `${PATH_ELEV_CONFIGURE}`,
      element: <Configuration />,
      children: [
        {
          path: `${PATH_ELEV_CONFIGURE}/:local_elevation_id`,
          element: <Configuration />,
        },
      ],
    },
    {
      path: `${PATH_ELEV_RESULTS}`,
      element: <Results />,
    },
    { path: '*', element: <Home /> },
  ]);
  const location = useLocation();
  if (!elements) return null;
  return <AnimatePresence mode="wait">{cloneElement(elements, { key: location.pathname })}</AnimatePresence>;
};

export default Router;
