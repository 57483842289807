import { FC, MouseEventHandler, useEffect, useRef, useState } from 'react';
import { animate, motion, useMotionTemplate, useMotionValue, useTransform } from 'framer-motion';

import './CustomSlider.styles.scss';

type Props = {
  value: number;
  setValue: (num: number) => void;
};

function clamp(number: number, min: number, max: number) {
  return Math.max(min, Math.min(number, max));
}

const chevronSvg = (
  <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.05413 6.65411H14.5002C14.5026 6.65411 14.5049 6.64943 14.5026 6.64943L9.43069 1.77443C9.02053 1.38068 9.00647 0.726769 9.40256 0.316613C9.79631 -0.0935431 10.4502 -0.107606 10.8604 0.288488L17.7674 6.92599C17.969 7.12052 18.0838 7.39005 18.0838 7.66896C18.0838 7.95021 17.969 8.21739 17.7674 8.41193L10.8229 15.0846C10.6237 15.2768 10.3658 15.3729 10.108 15.3729C9.83616 15.3729 9.56663 15.2674 9.36506 15.0565C8.97131 14.6463 8.98303 13.9924 9.39319 13.5986L14.4651 8.72364C14.4674 8.7213 14.4651 8.71896 14.4627 8.71896H1.03069C0.48225 8.71896 0.0345941 8.29005 0.00178164 7.75099C-0.0333746 7.15099 0.456469 6.65411 1.05413 6.65411Z"
      fill="white"
    />
  </svg>
);

const CustomSlider: FC<Props> = ({ value = 0, setValue }) => {
  let min = 0;
  let max = 100;

  let [dragging, setDragging] = useState<boolean>(false);
  let constraintsRef = useRef<HTMLDivElement>(null);

  let handleRef = useRef<HTMLDivElement>(null);
  let progressBarRef = useRef<HTMLDivElement>(null);
  let handleSize = 30;
  let handleX = useMotionValue(0);
  let progress = useTransform(handleX, (v) => v + handleSize / 2);
  let background = useMotionTemplate`linear-gradient(90deg, #B3070F ${progress}px, #eeee 0)`;

  function handleDrag() {
    if (handleRef.current && progressBarRef.current) {
      let handleBounds = handleRef.current.getBoundingClientRect();
      let middleOfHandle = handleBounds.x + handleBounds.width / 2;
      let progressBarBounds = progressBarRef.current.getBoundingClientRect();
      let newProgress = (middleOfHandle - progressBarBounds.x) / progressBarBounds.width;
      setValue(newProgress * (max - min));
    }
  }
  useEffect(() => {
    if (progressBarRef.current) {
      let newProgress = value / (max - min);
      let progressBarBounds = progressBarRef.current.getBoundingClientRect();

      if (dragging) handleX.set(newProgress * progressBarBounds.width);
      else animate(handleX, newProgress * progressBarBounds.width);
    }
  }, [handleX, max, min, value]);

  const onBarClick: MouseEventHandler = (event) => {
    if (progressBarRef.current) {
      let { left, width } = progressBarRef.current.getBoundingClientRect();
      let position = event.pageX - left;
      let newProgress = clamp(position / width, 0, 1);
      let newValue = newProgress * (max - min);
      setValue(newValue);
      animate(handleX, newProgress * width);
    }
  };

  return (
    <div data-testid="CustomSlider" className="relative w-full ">
      <div data-test="slider" className=" relative flex items-center">
        <motion.div data-test="slider-background" className="absolute w-full h-2 rounded-full" style={{ background }} />
        <div
          data-test="slider-progress"
          ref={progressBarRef}
          className="absolute h-2"
          style={{
            left: handleSize / 2,
            right: handleSize / 2,
          }}
        />
      </div>
      <div className="h-0" ref={constraintsRef}>
        <motion.div
          data-test="slider-handle"
          ref={handleRef}
          className="relative z-10 bg-wb-red  rounded-full cursor-pointer flex items-center justify-center "
          drag="x"
          dragMomentum={false}
          dragConstraints={constraintsRef}
          dragElastic={0}
          onDrag={handleDrag}
          onDragStart={() => setDragging(true)}
          onDragEnd={() => setDragging(false)}
          onPointerDown={() => setDragging(true)}
          onPointerUp={() => setDragging(false)}
          animate={{
            scale: dragging ? 1.2 : 1,
          }}
          style={{
            y: '-50%',
            width: handleSize,
            height: handleSize,
            x: handleX,
          }}
        >
          <div className="scale-[0.8] translate-y-[0.5px] ">{chevronSvg}</div>
        </motion.div>
      </div>
      <div
        data-test="slider-clickable-area"
        className="absolute w-full h-4 top-0 translate-y-[-50%] cursor-pointer "
        onClick={onBarClick}
      />
    </div>
  );
};

CustomSlider.propTypes = {};

export default CustomSlider;
