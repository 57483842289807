import { FC } from 'react';
import { LoadingSpinner } from 'components/elements/LoadingSpinner';

import { cc } from 'utilities/styles/styles.util';

interface ActionBtnProps {
  variant: 'prev' | 'next' | 'submit' | 'prev_2';
  onClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}

const ArrowLeft: FC<{ variant: string }> = ({ variant }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16.72" height="16.72" viewBox="0 0 16.72 16.72">
    <g id="Group_1344" data-name="Group 1344" transform="translate(16.72 16.72) rotate(180)">
      <circle
        id="Ellipse_25"
        data-name="Ellipse 25"
        cx="8.36"
        cy="8.36"
        r="8.36"
        fill={variant === 'secondary' ? '#737575' : '#fff'}
      />
      <path
        id="Icon_ionic-ios-arrow-round-forward"
        data-name="Icon ionic-ios-arrow-round-forward"
        d="M15.62,11.372a.421.421,0,0,0,0,.6l2.047,2H11.309a.427.427,0,1,0,0,.853h6.35l-2.047,2a.424.424,0,0,0,0,.6.444.444,0,0,0,.616,0L19.006,14.7h0a.478.478,0,0,0,.091-.135.4.4,0,0,0,.034-.164.422.422,0,0,0-.125-.3l-2.774-2.723A.437.437,0,0,0,15.62,11.372Z"
        transform="translate(-6.956 -5.873)"
        fill={variant === 'secondary' ? '#fff' : ''}
      />
    </g>
  </svg>
);

const ArrowRight: FC = () => (
  <svg
    id="Group_1326"
    data-name="Group 1326"
    xmlns="http://www.w3.org/2000/svg"
    width="16.717"
    height="16.717"
    viewBox="0 0 16.717 16.717"
  >
    <circle id="Ellipse_25" data-name="Ellipse 25" cx="8.359" cy="8.359" r="8.359" fill="#fff" />
    <path
      id="Icon_ionic-ios-arrow-round-forward"
      data-name="Icon ionic-ios-arrow-round-forward"
      d="M15.557,11.374a.434.434,0,0,0,0,.611l2.02,2.023H11.3a.432.432,0,0,0,0,.864H17.57L15.551,16.9a.437.437,0,0,0,0,.611.43.43,0,0,0,.608,0L18.9,14.746h0a.485.485,0,0,0,.09-.136.412.412,0,0,0,.033-.166.433.433,0,0,0-.123-.3l-2.738-2.757A.423.423,0,0,0,15.557,11.374Z"
      transform="translate(-6.907 -5.915)"
      fill="currentColor"
    />
  </svg>
);

const ActionBtn: FC<ActionBtnProps> = ({ onClick, disabled, variant, isLoading }) => {
  const icon =
    variant === 'prev' || variant === 'prev_2' ? (
      <ArrowLeft variant={'prev_2' ? 'secondary' : ''} />
    ) : (
      <div className="text-wb-red group-hover:text-black">
        <ArrowRight />
      </div>
    );
  const label = () => {
    switch (variant) {
      case 'next':
        return 'Confirm';
      case 'prev':
      case 'prev_2':
        return 'Back';
      case 'submit':
        return 'Submit';
      default:
        return '';
    }
  };
  return (
    <button
      className={cc([
        // default
        'group flex items-center justify-center rounded-md py-2 px-6 gap-3 font-light hover:opacity-90 active:scale-95 transition-all',
        // if prev flex-reverse
        variant === 'prev' ? 'flex-row-reverse bg-black text-white hover:bg-medium-grey hover:text-black' : '',

        variant === 'prev_2' ? 'flex-row-reverse bg-white text-medium-grey hover:bg-black hover:text-white' : '',
        // if next or submit colors
        variant === 'next' || variant === 'submit'
          ? disabled
            ? 'pointer-events-none bg-wb-red-lighter text-white'
            : 'bg-wb-red text-white hover:bg-black'
          : // if prev colours
          disabled
          ? 'pointer-events-none bg-medium-grey text-white'
          : '',
        '',
      ])}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {label()} {variant !== 'submit' && icon}
      {isLoading && <LoadingSpinner color="white" />}
    </button>
  );
};

export default ActionBtn;
