import { FC, useState } from 'react';
import { motion } from 'framer-motion';

import { ISingleElevationData } from '../../types';
import { SystemComponentsAccordion } from '../SystemComponentsTableAccordion';

import { SYSTEM_NAMES } from 'constants/system_names';

const startAnim = { opacity: 0 };
const anim = { opacity: 1 };

const TableSingleElevation: FC<ISingleElevationData> = ({ systems, units }) => {
  const [expanded, setExpanded] = useState<null | number>(null);

  const handleOnExpand = (number: number) => {
    if (expanded === number) setExpanded(null);
    else setExpanded(number);
  };

  return (
    <motion.div initial={startAnim} animate={anim}>
      {systems?.map((s, i) => {
        return (
          <SystemComponentsAccordion
            key={`system_${i}`}
            system={{ ...s, units, title: `System ${SYSTEM_NAMES[i]}` }}
            isOpen={expanded === i}
            onToggle={() => handleOnExpand(i)}
          />
        );
      })}
    </motion.div>
  );
};

TableSingleElevation.propTypes = {};

export default TableSingleElevation;
