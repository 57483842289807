import { PayloadAction, createSlice, current } from '@reduxjs/toolkit';
import { GridItem, GridPosition, GridRow, IGrid, X, Y } from 'types/grid';

// types

interface configuratorSliceState {
  isDragging: boolean;
  draggedShape: GridItem[];

  draggedPositions: GridPosition[];
  horizontalDragDirection: 'LEFT' | 'RIGHT' | undefined;
  verticalDragDirection: 'UP' | 'DOWN' | undefined;

  grid: IGrid;
  validSystems: GridItem[][][];
}

const initialState: configuratorSliceState = {
  grid: [],
  isDragging: false,
  draggedShape: [],
  horizontalDragDirection: undefined,
  verticalDragDirection: undefined,
  draggedPositions: [],
  validSystems: [],
};

type SetGridPaylod = {
  length: number;
  height: number;
};

const configuratorSlice = createSlice({
  name: 'configuratorSlice',
  initialState,
  reducers: {
    setGrid: (state, { payload }: PayloadAction<SetGridPaylod>) => {
      const { height, length } = payload;
      const grid: IGrid = [];
      for (let y: Y = 0; y < height; y++) {
        const row: GridRow = [];
        for (let x: X = 0; x < length; x++) {
          const gridItem: GridItem = {
            position: [y, x],
            selected: false,
            warning: false,
            dragged: false,
          };
          row.push(gridItem);
        }
        grid.push(row);
      }
      state.grid = grid;
    },
    setWarningsAndValidSystems: (state) => {
      if (!state?.grid?.length) {
        return;
      }
      const height = state.grid.length - 1;
      const width = state.grid[0].length - 1;

      state.grid.forEach((row) => row.forEach((cell) => (cell.warning = false)));

      state.validSystems = [];

      const getCellSurroundings = (item: GridItem) => {
        const [y, x] = item.position;

        const oneAbove = y >= 1 ? state.grid[y - 1][x] : null;
        const oneAboveRight = y >= 1 && x < width ? state.grid[y - 1][x + 1] : null;
        const twoAboveRight = y >= 1 && x < width ? state.grid[y - 1][x + 2] : null;
        const oneAboveLeft = y >= 1 && x >= 1 ? state.grid[y - 1][x - 1] : null;
        const twoAboveLeft = y >= 1 && x >= 1 ? state.grid[y - 1][x - 2] : null;

        const oneBelow = y < height ? state.grid[y + 1][x] : null;
        const oneBelowRight = y < height && x < width ? state.grid[y + 1][x + 1] : null;
        const twoBelowRight = y < height && x < width ? state.grid[y + 1][x + 2] : null;
        const oneBelowLeft = y < height && x >= 1 ? state.grid[y + 1][x - 1] : null;
        const twoBelowLeft = y < height && x >= 1 ? state.grid[y + 1][x - 2] : null;

        const oneRight = x < width ? state.grid[y][x + 1] : null;
        const oneLeft = x >= 1 ? state.grid[y][x - 1] : null;

        const twoRight = x <= width - 2 ? state.grid[y][x + 2] : null;
        const twoLeft = x >= 2 ? state.grid[y][x - 2] : null;

        const surroundings = {
          above: oneAbove,

          above_right: oneAboveRight,
          two_above_right: twoAboveRight,

          right: oneRight,
          two_right: twoRight,
          below_right: oneBelowRight,
          two_below_right: twoBelowRight,

          below: oneBelow,
          below_left: oneBelowLeft,
          two_below_left: twoBelowLeft,

          left: oneLeft,
          two_left: twoLeft,

          above_left: oneAboveLeft,
          two_above_left: twoAboveLeft,
        };

        return surroundings;
      };

      const isValidStartPosition = (item: GridItem) => {
        return Object.values(getCellSurroundings(item)).some((cell) => !!cell?.selected);
      };

      const singleSelectedWarnings = (item: GridItem) => {
        const [y, x] = item.position;

        const oneRight = x < width ? state.grid[y][x + 1] : null;
        const oneLeft = x >= 1 ? state.grid[y][x - 1] : null;
        if (oneRight && !oneRight?.selected) {
          oneRight.warning = true;
        } else if (oneLeft && !oneLeft.selected) {
          oneLeft.warning = true;
        }
      };

      const getColumnSelectedRange = (item: GridItem) => {
        const [_, x] = item.position;
        let column = [];
        for (let y: Y = 0; y <= height; y++) {
          column.push(current(state.grid[y][x]));
        }
        return column.map((item, index) => (item.selected ? index : false)).filter((e) => typeof e === 'number');
      };

      const getFurthestLeftValidItemsOfShapeFromStartPosition = (item: GridItem) => {
        let RETURN_ITEMS: GridItem[] = [];

        const shapeValidYIndexs = new Set<number>();

        let lastOneIndexs: number[] = [];
        let lastThreeIndexs: number[] = [];
        let lastTwoIndexs: number[] = [];

        const [startY, startX] = item.position;
        const startValidSet = new Set([startY]);

        // console.log('startpos', { y: startY, x: startX });

        const thisRange = getColumnSelectedRange(item);
        const startSlice = thisRange.slice(0, startY).reverse();
        const endSlice = thisRange.slice(startY);
        startSlice.forEach((i) => {
          if (startValidSet.has((i as number) - 1) || startValidSet.has((i as number) + 1)) {
            startValidSet.add(i as number);
          }
        });
        endSlice.forEach((i) => {
          if (startValidSet.has((i as number) - 1) || startValidSet.has((i as number) + 1)) {
            startValidSet.add(i as number);
          }
        });

        startValidSet.forEach((vi) => {
          shapeValidYIndexs.add(vi);
          lastOneIndexs.push(vi);
        });

        for (let i = 0; i <= height; i++) {
          // eslint-disable-next-line no-loop-func
          let arr = [...startValidSet];
          arr.forEach((i) => {
            let num = i as number;
            if (shapeValidYIndexs.has(num) || shapeValidYIndexs.has(num - 1) || shapeValidYIndexs.has(num + 1)) {
              startValidSet.add(num);
            }
          });
        }

        startValidSet.forEach((vi) => {
          shapeValidYIndexs.add(vi);
          lastOneIndexs.push(vi);
        });

        let noPrevCount = 0;
        let checkX = startX - 1;

        if (startX === 0) {
          let array: number[] = [];
          shapeValidYIndexs.forEach((v) => array.push(v));
          RETURN_ITEMS = array.map((y) => state.grid[y][startX]);
        }

        // console.log('shape-start:', shapeValidYIndexs);

        while (startX > 0 && checkX >= 0) {
          // console.log('while running', checkX);

          let thisValidYSet: number[] = [];

          const prevRange = getColumnSelectedRange(state.grid[0][checkX]).filter((item) => {
            let y = item as number;
            return !checkedPool.has(JSON.stringify(state.grid[y][checkX].position));
          });

          for (let i = 0; i <= height; i++) {
            // eslint-disable-next-line no-loop-func
            prevRange.forEach((i) => {
              let num = i as number;
              if (shapeValidYIndexs.has(num) || shapeValidYIndexs.has(num - 1) || shapeValidYIndexs.has(num + 1)) {
                thisValidYSet.push(num);
                shapeValidYIndexs.add(num);
              }
            });
          }

          thisValidYSet = [...new Set(thisValidYSet)];

          const noPrev = thisValidYSet.length === 0;

          if (noPrev) {
            noPrevCount = noPrevCount + 1;
          }

          if (noPrevCount === 1 && !noPrev) {
            noPrevCount = 0;
          }

          thisValidYSet.forEach((vi) => shapeValidYIndexs.add(vi));

          if (noPrevCount !== 2) {
            lastThreeIndexs = [...lastTwoIndexs];
            lastTwoIndexs = [...lastOneIndexs];
            lastOneIndexs = [...thisValidYSet];
          }

          // console.log('shape-loop:', shapeValidYIndexs);
          // console.log('lastThree-loop:', lastThreeIndexs);
          // console.log('lastTwo-loop:', lastTwoIndexs);
          // console.log('lastOne-loop:', lastOneIndexs);

          if (noPrevCount === 2) {
            // console.log('no prev');

            if (lastTwoIndexs.length) {
              RETURN_ITEMS = lastTwoIndexs.map((y) => state.grid[y][checkX + 2]);
            } else {
              let array: number[] = [];
              startValidSet.forEach((v) => array.push(v));
              RETURN_ITEMS = array.map((y) => state.grid[y][startX]);
            }

            checkX = -1;
          } else if (checkX === 0) {
            let x = checkX;

            // console.log({ noPrevCount });

            if (noPrevCount === 1) {
              x = x + 1;
              if (lastOneIndexs.length) {
                RETURN_ITEMS = lastOneIndexs.map((y) => state.grid[y][x]);
              } else if (lastTwoIndexs.length) {
                RETURN_ITEMS = lastTwoIndexs.map((y) => state.grid[y][x]);
              } else if (lastThreeIndexs.length) {
                RETURN_ITEMS = lastThreeIndexs.map((y) => state.grid[y][x]);
              } else {
                let array: number[] = [];
                startValidSet.forEach((v) => array.push(v));
                RETURN_ITEMS = array.map((y) => state.grid[y][startX]);
              }
            } else {
              if (lastOneIndexs.length) {
                RETURN_ITEMS = lastOneIndexs.map((y) => state.grid[y][x]);
              } else if (lastTwoIndexs.length) {
                RETURN_ITEMS = lastTwoIndexs.map((y) => state.grid[y][x]);
              } else if (lastThreeIndexs.length) {
                RETURN_ITEMS = lastThreeIndexs.map((y) => state.grid[y][x]);
              }
            }

            // eslint-disable-next-line no-loop-func

            checkX = checkX - 1;
          } else {
            checkX = checkX - 1;
          }
        }

        return RETURN_ITEMS;
      };

      const getShapeFromFurthestLeftValidItems = (items: GridItem[]) => {
        let RETURN_ITEMS: GridItem[] = [];

        // console.log(
        //   'start-items:',
        //   items.map((item) => current(item.position))
        // );

        let top = height;
        let bottom = 0;

        const validShapeYIndexs = new Set<number>();

        const [startY, startX] = items[0].position;

        // console.log('start left:', { y: startY, x: startX });

        const startYIndexs = items.map((i) => i.position[0]);
        const startValidSet = new Set(startYIndexs);

        startValidSet.forEach((vi) => {
          if (vi <= top) top = vi;
          if (vi >= bottom) bottom = vi;
          validShapeYIndexs.add(vi);
        });

        // console.log('shape-start:', validShapeYIndexs);

        let noNextCount = 0;

        let checkX = startX + 1;

        while (checkX <= width) {
          let nextValidYSet: number[] = [];

          const nextRange = getColumnSelectedRange(state.grid[startY][checkX]);

          for (let i = 0; i <= height; i++) {
            // eslint-disable-next-line no-loop-func
            nextRange.forEach((i) => {
              let num = i as number;
              // console.log(validShapeYIndexs);
              if (validShapeYIndexs.has(num) || validShapeYIndexs.has(num - 1) || validShapeYIndexs.has(num + 1)) {
                nextValidYSet.push(num);
                validShapeYIndexs.add(num);
              }
            });
          }

          nextValidYSet = [...new Set(nextValidYSet)];

          // console.log('next-valid:', nextValidYSet);

          const noNext = nextValidYSet.length === 0;

          if (noNext) {
            noNextCount = noNextCount + 1;
          }

          if (noNextCount === 1 && !noNext) {
            noNextCount = 0;
          }

          // console.log('top-bottom-before-loop:', { top, bottom });
          // eslint-disable-next-line no-loop-func
          validShapeYIndexs.forEach((vi) => {
            if (vi < top) top = vi;
            if (vi > bottom) bottom = vi;
          });

          // console.log('top-bottom-after-loop:', { top, bottom });

          if (noNextCount === 2) {
            let array: number[] = [];
            validShapeYIndexs.forEach((v) => array.push(v));
            // eslint-disable-next-line no-loop-func
            RETURN_ITEMS = array.map((y) => current(state.grid[y][checkX - 2]));

            checkX = width + 1;
          } else if (checkX === width) {
            let x = checkX;

            if (noNextCount === 1) {
              x = x - 1;
            }

            let array: number[] = [];
            validShapeYIndexs.forEach((v) => array.push(v));
            // eslint-disable-next-line no-loop-func
            RETURN_ITEMS = array.map((y) => current(state.grid[y][x]));

            checkX = checkX + 1;
          } else {
            checkX = checkX + 1;
          }
        }

        let endX = typeof RETURN_ITEMS[0]?.position[1] === 'number' ? RETURN_ITEMS[0]?.position[1] : width;
        endX = endX + 1;

        let sX = startX;

        // console.log('before-final:', { top, bottom, startX: sX, endX });

        const isOneCol = endX === sX + 1;

        const isEndAndOneCol = sX === width && isOneCol;

        if (!isEndAndOneCol && isOneCol) {
          endX = endX + 1;
        }

        if (isEndAndOneCol) {
          sX = sX - 1;
        }

        let shape = state.grid
          .map((row, i) => {
            if (i >= top && i <= bottom) {
              return row.slice(sX, endX);
            } else return false;
          })
          .filter(Boolean);

        return shape;
      };

      const checkedPool = new Set<string>();

      state.grid.forEach((row) =>
        row.forEach((cell) => {
          if (!checkedPool.has(JSON.stringify(cell.position))) {
            const isStartPos = isValidStartPosition(cell);
            const isSelected = cell?.selected;

            if (!isStartPos && isSelected) {
              singleSelectedWarnings(cell);
            }

            if (isStartPos && isSelected) {
              const leftValidItems = getFurthestLeftValidItemsOfShapeFromStartPosition(cell);

              const shape = getShapeFromFurthestLeftValidItems(leftValidItems) as GridItem[][];

              let warningCount = 0;
              shape.forEach((row) => {
                if (row) {
                  row.forEach((el) => {
                    if (el) {
                      // console.log(JSON.stringify(el.position));
                      checkedPool.add(JSON.stringify(el.position));
                      if (!el.selected) {
                        el.warning = true;
                      }
                      if (el.warning) warningCount++;
                    }
                  });
                }
              });

              if (warningCount === 0) {
                state.validSystems.push(shape);
              }
            }
          }
        })
      );

      checkedPool.clear();
    },
    handleCellClick: (state, { payload }: PayloadAction<GridPosition>) => {
      const [y, x] = payload;
      const thisCell = state.grid[y][x];
      thisCell.selected = !thisCell.selected;
    },
    onDragStart: (state) => {
      state.isDragging = true;
    },
    onDragEnd: (state) => {
      state.isDragging = false;

      state.draggedShape.forEach((el) => {
        const item = state.grid[el.position[0]][el.position[1]];
        item.dragged = false;
        item.selected = !item.selected;
      });

      state.draggedShape = [];
      state.draggedPositions = [];
      state.horizontalDragDirection = undefined;
      state.verticalDragDirection = undefined;
    },
    addDragPosition: (state, { payload }: PayloadAction<GridPosition>) => {
      state.grid.forEach((row) =>
        row.forEach((cell) => {
          cell.dragged = false;
        })
      );

      state.draggedPositions.push(payload);

      const [startY, startX] = state.draggedPositions[0];

      if (state.draggedPositions[1]) {
        const [secondY, secondX] = state.draggedPositions[1];
        if (secondX > startX) {
          state.horizontalDragDirection = 'RIGHT';
        }
        if (secondX < startX) {
          state.horizontalDragDirection = 'LEFT';
        }

        if (secondY > startY) {
          state.verticalDragDirection = 'DOWN';
        }
        if (secondY < startY) {
          state.verticalDragDirection = 'UP';
        }
      }

      if (state.horizontalDragDirection === 'LEFT') {
        const lastPos = state.draggedPositions[state.draggedPositions.length - 1];
        const secondLastPos = state.draggedPositions[state.draggedPositions.length - 2];

        if (secondLastPos && lastPos) {
          const [lastY, lastX] = lastPos;
          const [sLastY, sLastX] = secondLastPos;

          if (lastX > sLastX) {
            state.draggedPositions.splice(1, Math.max(1, state.draggedPositions.length - 2));
          }
        }
      }

      if (state.horizontalDragDirection === 'RIGHT') {
        const lastPos = state.draggedPositions[state.draggedPositions.length - 1];
        const secondLastPos = state.draggedPositions[state.draggedPositions.length - 2];

        if (state.draggedPositions.length > 2) {
          if (secondLastPos && lastPos) {
            const [lastY, lastX] = lastPos;
            const [sLastY, sLastX] = secondLastPos;

            if (lastX < sLastX) {
              state.draggedPositions.splice(1, Math.max(1, state.draggedPositions.length - 2));
            }
          }
        }
      }

      if (state.verticalDragDirection === 'DOWN') {
        const lastPos = state.draggedPositions[state.draggedPositions.length - 1];
        const secondLastPos = state.draggedPositions[state.draggedPositions.length - 2];

        if (state.draggedPositions.length > 1) {
          if (secondLastPos && lastPos) {
            const [lastY] = lastPos;
            const [sLastY] = secondLastPos;

            if (lastY < sLastY) {
              state.draggedPositions.splice(1, Math.max(1, state.draggedPositions.length - 2));
            }
          }
        }
      }

      if (state.verticalDragDirection === 'UP') {
        const lastPos = state.draggedPositions[state.draggedPositions.length - 1];
        const secondLastPos = state.draggedPositions[state.draggedPositions.length - 2];

        if (state.draggedPositions.length > 1) {
          if (secondLastPos && lastPos) {
            const [lastY, lastX] = lastPos;
            const [sLastY, sLastX] = secondLastPos;

            if (lastY > sLastY) {
              state.draggedPositions.splice(1, Math.max(1, state.draggedPositions.length - 2));
            }
          }
        }
      }

      const Yarray = state.draggedPositions.map((pos) => pos[0]);
      const Xarray = state.draggedPositions.map((pos) => pos[1]);

      const BOTTOM = Math.max(...Yarray);
      const TOP = Math.min(...Yarray);
      const RIGHT = Math.max(...Xarray);
      const LEFT = Math.min(...Xarray);

      const shape = state.grid
        .map((row, i) => {
          if (i >= TOP && i <= BOTTOM) {
            return row.slice(LEFT, Math.max(RIGHT, LEFT) + 1);
          } else return false;
        })
        .filter(Boolean)
        .flat(1);

      shape.forEach((el) => {
        if (el) {
          el.dragged = true;
        }
      });

      state.draggedShape = shape as GridItem[];
    },
    populateGrid: (state, { payload }: PayloadAction<IGrid>) => {
      state.grid = payload;
    },
    clearGrid: (state) => {
      state.grid.forEach((row) =>
        row.forEach((cell) => {
          cell.selected = false;
          cell.warning = false;
        })
      );
      state.validSystems = [];
    },
    setMaxPower: (state) => {
      state.grid.forEach((row) => row.forEach((cell) => (cell.selected = true)));
    },
  },
});

// export for use around the app
export const {
  setGrid,
  handleCellClick,
  onDragStart,
  onDragEnd,
  addDragPosition,
  setWarningsAndValidSystems,
  populateGrid,
  clearGrid,
  setMaxPower,
} = configuratorSlice.actions;

// export for store
const configuratorReducer = configuratorSlice.reducer;

export default configuratorReducer;
