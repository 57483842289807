export const MOCK_FAQS = [
  {
    title: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr?',
    content: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
  },
  {
    title: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr?',
    content: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
  },
  {
    title: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr?',
    content: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
  },
  {
    title: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr?',
    content: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
  },
];

export const MOCK_RESOURCE_ITEMS = [
  {
    title: 'Resource Guides',
    items: [
      {
        resource_url: 'some_url',
        text: 'Installation guide',
        img_url:
          'https://images.unsplash.com/photo-1541888946425-d81bb19240f5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
      },
      {
        resource_url: '',
        text: 'Installation guide',
        img_url:
          'https://images.unsplash.com/photo-1541888946425-d81bb19240f5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
      },
      {
        resource_url: 'some_url',
        text: 'Installation guide',
        img_url:
          'https://images.unsplash.com/photo-1541888946425-d81bb19240f5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
      },
    ],
  },
  {
    title: 'Product List',
    items: [
      {
        resource_url: 'some_url',
        text: 'Installation guide',
        img_url:
          'https://images.unsplash.com/photo-1541888946425-d81bb19240f5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
      },
      {
        resource_url: '',
        text: 'Installation guide',
        img_url:
          'https://images.unsplash.com/photo-1541888946425-d81bb19240f5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
      },
      {
        resource_url: 'some_url',
        text: 'Installation guide',
        img_url:
          'https://images.unsplash.com/photo-1541888946425-d81bb19240f5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
      },
    ],
  },
];
