import { FC } from 'react';
import './ElevationButton.styles.scss';

type Props = {
  onClick(): void;
};

const PlusIconSVG: FC<{width?: number}> = ({width = 30}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 42.513 41.394" className="rotate-45">
      <g id="Group_1511" data-name="Group 1511" transform="translate(-81 104.394) rotate(-90)">
        <g id="Group_1513" data-name="Group 1513" transform="translate(63 81)">
          <ellipse id="Ellipse_2" data-name="Ellipse 2" cx="20.697" cy="21.256" rx="20.697" ry="21.256" />
          <g id="Group_1512" data-name="Group 1512">
            <path
              id="Path_4"
              data-name="Path 4"
              d="M7.984,9.516.317,1.849A1.083,1.083,0,0,1,1.849.317L7.984,6.451,14.118.317A1.083,1.083,0,0,1,15.65,1.849Z"
              transform="translate(12.711 13.319)"
              fill="#fff"
            />
            <path
              id="Path_4-2"
              data-name="Path 4"
              d="M7.984,9.516.317,1.849A1.083,1.083,0,0,1,1.849.317L7.984,6.451,14.118.317A1.083,1.083,0,0,1,15.65,1.849Z"
              transform="translate(28.679 29.835) rotate(180)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

const ElevationButton: FC<Props> = ({ onClick }) => {
  return (
    <button
      data-testid="ElevationButton"
      className="ElevationButton flex justify-between items-center elevation-header bg-light-grey rounded-[20px] p-4 md:p-6 w-full"
      onClick={onClick}
    >
      Add Elevation
      <div>
        <PlusIconSVG width={24} />
      </div>
    </button>
  );
};

ElevationButton.propTypes = {};

export default ElevationButton;
