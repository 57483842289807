import { FC } from 'react';
import './Checkbox.styles.scss';

import { RHFErrorMessage } from 'types/form';
import { FormError } from '../FormError';

interface CheckboxProps {
  label: string | React.ReactNode;
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  error?: RHFErrorMessage;
}

const Checkbox: FC<CheckboxProps> = ({ label, checked, onChange, error }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    onChange(isChecked);
  };

  return (
    <div>
      <label className="flex items-center gap-2 hover:cursor-pointer">
        <input className="custom-checkbox" type="checkbox" checked={checked} onChange={handleChange} />
        {label}
      </label>
      {error && <FormError message={error} />}
    </div>
  );
};

export default Checkbox;
