import { FC } from 'react';

type Props = {
  text: string;
};

const TableSystemTitle: FC<Props> = ({ text }) => {
  return (
    <h3 data-testid="TableSystemTitle" className="text-xl text-wb-red font-bold">
      {text}
    </h3>
  );
};

TableSystemTitle.propTypes = {};

export default TableSystemTitle;
