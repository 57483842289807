import { FC } from 'react';
import './HouseTypeButton.styles.scss';

import SingleElevation from 'assets/images/house-single-elev.png';
import MultiElevation from 'assets/images/house-multi-elev.png';

import { ElevationType } from 'types/general';

type Props = {
  onClick(type: ElevationType): void;
  type: ElevationType;
};

const HouseTypeButton: FC<Props> = ({ onClick, type }) => {
  return (
    <button
      id={type}
      onClick={() => onClick(type)}
      className="w-[320px] bg-light-grey rounded-[20px] p-4 hover:scale-105 transition-all duration-300"
    >
      <img src={type === 'single' ? SingleElevation : MultiElevation} alt={`${type}_elevation`} />
      {
        <p className="my-6 text-2xl text-center font-bold">
          {type === 'single' ? 'Single Elevation' : 'Multiple Elevations'}
        </p>
      }
    </button>
  );
};

HouseTypeButton.propTypes = {};

export default HouseTypeButton;
