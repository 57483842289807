import { FC } from 'react';

import { TableText } from 'components/typography/TableText';
import { ChevronBtn } from 'components/buttons/ChevronBtn';
import { PowerIcon } from 'components/elements/PowerIcon';

import { TableSystemCell } from '../TableSystemCell';
import { TableSystemTitle } from '../TableSystemTitle';

import './TableSystemGrid.styles.scss';

import { ISystemInfoData } from '../../types';

import { generateSystemWidthAndHeightText } from '../../utils';
import { wpToKwpConverter } from 'utilities/helpers/wpToKwpConverter';

interface TableSystemGridProps {
  data: ISystemInfoData;
  onToggle: () => void;
  isOpen: boolean;
}

const TableSystemGrid: FC<TableSystemGridProps> = ({ data, isOpen, onToggle }) => {
  const {
    height_flashing,
    height_net,
    system_power,
    title,
    width_flashing,
    width_net,
    units = 'mm',
    rows,
    columns,
  } = data;

  const { withFlashing, withoutFlashing } = generateSystemWidthAndHeightText({
    height_flashing,
    height_net,
    units,
    width_flashing,
    width_net,
  });

  return (
    <div
      data-testid="TableSystemGrid"
      className="table-system-grid flex flex-col gap-6 lg:gap-0 lg:grid pt-6 pb-2 lg:py-6 "
    >
      <TableSystemCell paddingRight>
        <div className="flex justify-between items-center">
          {title && <TableSystemTitle text={title} />}
          <div className="lg:hidden">
            <ChevronBtn open={isOpen} onClick={onToggle} />
          </div>
        </div>
      </TableSystemCell>
      <TableSystemCell paddingRight>
        <div className="flex lg:items-center gap-3">
          <PowerIcon className="scale-105" />
          <div>
            <TableText variant="bold" text="Power output:" />
            <TableText variant="normal" text={`${wpToKwpConverter(system_power)} kWp`} />
          </div>
        </div>
      </TableSystemCell>
      <TableSystemCell borderLeft paddingLeft paddingRight>
        <div className="flex lg:items-center">
          <div>
            <TableText variant="bold" text="Without flashing:" />
            <TableText variant="normal" text={withoutFlashing} />
          </div>
        </div>
      </TableSystemCell>
      <TableSystemCell borderLeft paddingLeft>
        <div className="flex lg:items-center">
          <div>
            <TableText variant="bold" text="With flashing:" />
            <TableText variant="normal" text={withFlashing} />
          </div>
        </div>
      </TableSystemCell>
      <TableSystemCell borderLeft paddingLeft>
        <div className="flex lg:items-center">
          <div>
            <TableText variant="bold" text="Size:" />
            <TableText variant="normal" text={`Rows ${rows} Columns ${columns}`} />
          </div>
        </div>
      </TableSystemCell>
      <TableSystemCell>
        <div className="hidden lg:flex justify-end">
          <ChevronBtn open={isOpen} onClick={onToggle} />
        </div>
      </TableSystemCell>
    </div>
  );
};

export default TableSystemGrid;
