import { FC } from 'react';

import { Power } from 'assets/images';

type Props = {
  className?: string;
};

const PowerIcon: FC<Props> = ({ className }) => {
  return <img src={Power} alt="power" className={className} />;
};

PowerIcon.propTypes = {};

export default PowerIcon;
