import { FC } from 'react';

interface BinBtnProps {
  onClick: () => void;
  disabled?: boolean;
}

const BinBtn: FC<BinBtnProps> = ({ onClick, disabled }) => {
  return (
    <button
      onClick={onClick}
      data-testid="BinBtn"
      className={`transition-all cursor-pointer active:scale-90 text-black hover:text-gray-700  ${
        disabled ? 'pointer-events-none opacity-60' : ''
      }`}
    >
      <svg
        id="Group_1437"
        data-name="Group 1437"
        xmlns="http://www.w3.org/2000/svg"
        width="40.817"
        height="40.817"
        viewBox="0 0 40.817 40.817"
      >
        <g id="Group_1348" data-name="Group 1348" transform="translate(0)">
          <circle fill="currentColor" id="Ellipse_25" data-name="Ellipse 25" cx="20.408" cy="20.408" r="20.408" />
          <g id="Group_1347" data-name="Group 1347" transform="translate(11.477 10.484)">
            <path
              id="Path_3"
              data-name="Path 3"
              d="M4.5,9H22.364"
              transform="translate(-4.5 -5.03)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
            <path
              id="Path_4"
              data-name="Path 4"
              d="M21.394,6.97V20.864a1.985,1.985,0,0,1-1.985,1.985H9.485A1.985,1.985,0,0,1,7.5,20.864V6.97m2.977,0V4.985A1.985,1.985,0,0,1,12.462,3h3.97a1.985,1.985,0,0,1,1.985,1.985V6.97"
              transform="translate(-5.515 -3)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
            <path
              id="Path_5"
              data-name="Path 5"
              d="M15,16.5v5.955"
              transform="translate(-8.053 -7.568)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
            <path
              id="Path_6"
              data-name="Path 6"
              d="M21,16.5v5.955"
              transform="translate(-10.083 -7.568)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
          </g>
        </g>
      </svg>
    </button>
  );
};

BinBtn.propTypes = {};

export default BinBtn;
