import { FC } from 'react';

type Props = {
  color: 'white' | 'black';
  customClasses?: string;
};

const LoadingSpinner: FC<Props> = ({ color = 'black', customClasses = '' }) => {
  const colorStyles =
    color === 'black' ? 'border-gray-900 border-r-transparent' : 'border-gray-100 border-r-transparent';

  return (
    <div
      className={`inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite] ${colorStyles} ${customClasses}`}
      role="status"
    />
  );
};

export default LoadingSpinner;
