import { FC } from 'react';

type Props = {
  text: string;
  variant?: 'bold' | 'normal';
};

const TableText: FC<Props> = ({ text, variant = 'normal' }) => {
  return (
    <p className={`text-medium-grey  lg:text-17 ${variant === 'bold' ? 'font-semibold' : 'font-normal'}`}>{text}</p>
  );
};

export default TableText;
