import { configureStore } from '@reduxjs/toolkit';

// reducers
import configuratorReducer from './slices/configurator/configurator.slice';
import measurementsReducer from './slices/measurements/measurements.slice';
import resultsReducer from './slices/results/results.slice';

export const store = configureStore({
  reducer: {
    measurements: measurementsReducer,
    configurator: configuratorReducer,
    results: resultsReducer,
  },
});
